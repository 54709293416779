import React from 'react'
import { CgSpinner } from 'react-icons/cg'

function Loader() {
  return (
    <div className={'bg-background fixed z-50 w-full h-lvh top-0 left-0 flex items-center justify-center'}>
        <div className='mt-[-100px] space-y-4'>
          <center><CgSpinner className='w-8 h-8 animate-spin' /></center>
          {/* <center><span>{process.env.REACT_APP_NAME!}</span></center> */}
        </div>
    </div>
  )
}

export default Loader