
import React from 'react'
import { CiMoneyCheck1 } from "react-icons/ci";
import { CiBitcoin } from "react-icons/ci";
import { CiBank } from "react-icons/ci";
import { useNavigate, useParams } from 'react-router-dom';

function FundAccount() {
    
    const navigate = useNavigate();
    const { id } = useParams();
    
    return (
        <div>

            <div className='mb-[60px]'>
                <div className='text-2xl font-pbold'>Fund Account</div>
                <div className='font-plight text-sm'>Top up your account using various methods</div>
            </div>

            <div className='space-y-10'>
                <div className='flex gap-5 items-center rounded-xl px-10 py-8 border cursor-pointer' onClick={() => navigate(`/d/accounts/${id}/fund/cheque`)}>
                    <div>
                        <CiMoneyCheck1 className='w-8 h-8' />
                    </div>
                    <div className='text-left'>
                        <div className='text-xl font-pbold'>Deposit cheque</div>
                        <div className='font-plight text-sm'>Fund account by depositing cheques</div>
                    </div>
                </div>

                <div className='flex gap-5 items-center rounded-xl px-10 py-8 border cursor-pointer' onClick={() => navigate(`/d/accounts/${id}/fund/crypto`)}>
                    <div>
                        <CiBitcoin className='w-8 h-8' />
                    </div>
                    <div className='text-left'>
                        <div className='text-xl font-pbold'>Deposit digital currency</div>
                        <div className='font-plight text-sm'>Fund account using bitcoin, usdc, usdt and so on</div>
                    </div>
                </div>

                <div className='flex gap-5 items-center rounded-xl px-10 py-8 border text-foreground/50 cursor-not-allowed'>
                    <div>
                        <CiBank className='w-8 h-8' />
                    </div>
                    <div className='text-left'>
                        <div className='text-xl font-pbold'>ACH Deposit</div>
                        <div className='font-plight text-sm'>Fund account using ACH transfer</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FundAccount