import React, { useState } from 'react'
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/_components/ui/dialog"
import { Label } from '@/_components/ui/label';
import { Button } from '@/_components/ui/button';
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
} from "@/_components/ui/select"
import { useApp } from '@/contexts/appContext';
import { CgSpinner } from 'react-icons/cg';
import { toast } from '@/_components/ui/use-toast';
import http from '@/lib/http';
import { ISavings } from '@/types/global.types';
import { Input } from '@/_components/ui/input';

function SaveInitiate({ children, setSavings }: { children: React.ReactNode, setSavings: React.Dispatch<React.SetStateAction<ISavings[]>> }) {

    const { user } = useApp();
    const [isOpen, setIsOpen] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [data, setData] = useState({
        account_id: "",
        name: "",
        amount: "",
        expires_at: "",
    });

    function getAccountByType(currency: "usd" | "eur", type: "checking" | "savings") {
        const accounts_ = user!.accounts;
        const accounts = accounts_.filter((a) => a.name === currency.toLowerCase());
        return accounts.filter((a) => a.type === type)[0];
    }

    async function handleCreate() {
        setIsSending(true);
        try {
            const resp = await http().post('/user/savings/initiate', data);
            setSavings((u) => ([...u, resp.data.data]));
            // setSavings(resp.data.data);
            console.log(resp.data.data)
            toast({
                title: "Success",
                description: resp.data.message,
                variant: 'default',
                duration: 3000
            });
            setTimeout(() => {
                window.location.reload();
            }, 3000)
        } catch (error) {
            console.log(error);
            toast({
                title: "Error",
                description: (error as any).response.data.message,
                variant: 'destructive',
                duration: 2000
            })
        } finally {
            setIsOpen(false);
            setIsSending(false);
        }
    }

    function isSaveInitiateValid() {
        if (data.account_id.length > 0 && data.name.length > 0 && data.amount.length > 0 && data.expires_at.length > 0 && !isSending) {
            return true;
        }
        return false;
    }

    return (
        <Dialog open={isOpen} onOpenChange={(e) => setIsOpen(e)}>
            <DialogTrigger asChild onClick={() => setIsOpen(true)}>
                {children}
            </DialogTrigger>
            <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                    <DialogTitle>Lock funds</DialogTitle>
                    <DialogDescription>
                        Earn interest on locked funds
                    </DialogDescription>
                </DialogHeader>

                <div className="grid gap-4 mt-4">
                    <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="name" className="text-right">
                            Account
                        </Label>
                        <Select value={data.account_id} onValueChange={(e) => setData((d) => ({ ...d, account_id: e }))}>
                            <SelectTrigger className="w-[180px]">
                                <SelectValue placeholder="Select account" />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectGroup>
                                    <SelectLabel>Accounts</SelectLabel>
                                    <SelectItem value={getAccountByType("usd", "savings").id.toString()}>
                                        <div className='flex items-center gap-2'>
                                            <img src="/assets/flags/usd.svg" className='w-4' alt="" />
                                            <div>Savings</div>
                                        </div>
                                    </SelectItem>
                                    <SelectItem value={getAccountByType("usd", "checking").id.toString()}>
                                        <div className='flex items-center gap-2'>
                                            <img src="/assets/flags/usd.svg" className='w-4' alt="" />
                                            <div>Checking</div>
                                        </div>
                                    </SelectItem>
                                    <SelectItem value={getAccountByType("eur", "savings").id.toString()}>
                                        <div className='flex items-center gap-2'>
                                            <img src="/assets/flags/eur.svg" className='w-4' alt="" />
                                            <div>Savings</div>
                                        </div>
                                    </SelectItem>
                                    <SelectItem value={getAccountByType("eur", "checking").id.toString()}>
                                        <div className='flex items-center gap-2'>
                                            <img src="/assets/flags/eur.svg" className='w-4' alt="" />
                                            <div>Checking</div>
                                        </div>
                                    </SelectItem>
                                </SelectGroup>
                            </SelectContent>
                        </Select>
                    </div>
                </div>

                <div className="grid gap-4">
                    <div className="grid grid-cols-4 items-center gap-4 mb-4">
                        <Label htmlFor="name" className="text-right">
                            Enter title
                        </Label>
                        <Input
                            type='text'
                            id="name"
                            className="col-span-3"
                            value={data.name}
                            onChange={(e) => setData((d) => ({ ...d, name: e.target.value }))}
                        />
                    </div>
                </div>

                <div className="grid gap-4">
                    <div className="grid grid-cols-4 items-center gap-4 mb-4">
                        <Label htmlFor="name" className="text-right">
                            Amount
                        </Label>
                        <Input
                            type='number'
                            id="amount"
                            className="col-span-3"
                            value={data.amount}
                            onChange={(e) => setData((d) => ({ ...d, amount: e.target.value }))}
                        />
                    </div>
                </div>

                <div className="grid gap-4">
                    <div className="grid grid-cols-4 items-center gap-4 mb-4">
                        <Label htmlFor="name" className="text-right">
                            Expires
                        </Label>
                        <Input
                            type='datetime-local'
                            id="expires_at"
                            className="col-span-3"
                            onChange={(e) => setData((d) => ({ ...d, expires_at: e.target.value }))}
                        />
                    </div>
                </div>

                <DialogFooter>
                    <Button type="submit" disabled={!isSaveInitiateValid()} onClick={() => handleCreate()}>
                        {
                            !isSending ?
                                <span>Create</span> :
                                <CgSpinner className='animate-spin' />
                        }
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}

export default SaveInitiate