import { Toaster } from '@/_components/ui/toaster'
import React from 'react'
import { Outlet } from 'react-router-dom'

function AuthLayout() {
  return (
    <>
      <div className='grid grid-cols-1 md:grid-cols-2'>
        <div className='flex items-center min-h-lvh w-full justify-center'>
          <div className='w-[95%] sm:w-[450px] md:w-[70%] px-[20px] md:px-0'>
            <Outlet />
          </div>
        </div>
        <div>
          <div className='hidden relative md:flex items-center md:min-h-lvh bg-neutral-200 dark:bg-slate-950'>
            <div className='relative overflow-hidden'>
              <img className='dark:hidden inline-block border-4 border-neutral-300/35 dark:border-slate-800/45 rounded-xl relative top-0 right-[-100px]' src="/assets/screenshot-overview.png" alt="screenshot" />
              <img className='hidden dark:inline-block border-4 border-neutral-300/35 dark:border-slate-800/45 rounded-xl relative top-0 right-[-100px]' src="/assets/screenshot-overview2.png" alt="screenshot" />
            </div>
          </div>
        </div>
      </div>

      <Toaster />
    </>
  )
}

export default AuthLayout