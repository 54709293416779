
import React from 'react'

import {
    Card,
    CardContent,
} from "@/_components/ui/card"
import { Switch } from '@/_components/ui/switch'

function Preference() {
    return (
        <div>
            <div className='mb-[20px]'>
                <div className='text-2xl font-pbold'>Account Preference</div>
                <div className='font-plight text-sm'>Manage account preference, two factor authentication and notification</div>
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2'>
                <div className='space-y-[20px]'>
                    <Card>
                        <CardContent className="pt-5">
                            <div className='flex justify-between items-center gap-4 md:gap-10'>
                                <div className="space-y-1">
                                    <div className='font-psemibold'>Two factor authentication</div>
                                    <div className='text-sm font-plight'>Secure your account with two factor authentication. Enable this to authenticate every login with a one time pin sent to your email.</div>
                                </div>

                                <div>
                                    <Switch id="2fa" checked={true}/>
                                </div>
                            </div>
                        </CardContent>
                    </Card>

                    <Card>
                        <CardContent className="pt-5">
                            <div className='flex justify-between items-center gap-4 md:gap-10'>
                                <div className="space-y-1">
                                    <div className='font-psemibold'>Notifications</div>
                                    <div className='text-sm font-plight'>Receive transaction notifications in your registered email address. Enable this to receive prompt notifications for every transaction made on your account.</div>
                                </div>

                                <div>
                                    <Switch id="notifications" checked={true}/>
                                </div>
                            </div>
                        </CardContent>
                    </Card>

                    <Card>
                        <CardContent className="pt-5">
                            <div className='flex justify-between items-center gap-4 md:gap-10'>
                                <div className="space-y-1">
                                    <div className='font-psemibold'>Newsletter</div>
                                    <div className='text-sm font-plight'>Subscribe to our newsletter to stay up to date on our promotions and services. Be the first to jump on our exclusive deals.</div>
                                </div>

                                <div>
                                    <Switch id="newsletter" checked={true}/>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                </div>

                <div className='flex gap-10 '>
                    <div></div>
                </div>
            </div>
        </div>
    )
}

export default Preference