
import React, { useEffect, useState } from 'react'

import { Button } from '@/_components/ui/button'

import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from "@/_components/ui/card"

import { TrendingUp } from "lucide-react"
import {
    Label,
    PolarGrid,
    PolarRadiusAxis,
    RadialBar,
    RadialBarChart,
} from "recharts"
import { ChartConfig, ChartContainer } from "@/_components/ui/chart"
import { useApp } from '@/contexts/appContext'
import { formatDate, numberFormat, upperCaseFirst } from '@/utils/helpers'
import LoanInitiate from '@/components/LoanInitiate'
import http from '@/lib/http'
import { toast } from '@/_components/ui/use-toast'
import { ILoan } from '@/types/global.types'
import { CgSpinner } from 'react-icons/cg'

function Loans() {

    const { user } = useApp();

    const [loans, setLoans] = useState<ILoan[]>([])
    const [isFetching, setIsFetching] = useState(true)

    async function getLaons() {
        try {
            const resp = await http().get('/user/loans');
            setLoans(resp.data.data);
        } catch (error) {
            console.log(error)
        } finally {
            setIsFetching(false);
        }
    }

    async function handleRepayLoan(id: number) {
        if (window.confirm("Ae you sure")) {
            try {
                const resp = await http().post('/user/loan/repay', { loan_id: `${id}` });
                toast({
                    title: "Success",
                    description: resp.data.message,
                    variant: 'default',
                    duration: 3000
                });
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            } catch (error) {
                console.log(error)
                toast({
                    title: "Error",
                    description: (error as any).response.data.message,
                    variant: 'destructive',
                    duration: 3000
                })
            }
        }
    }

    useEffect(() => {
        getLaons();
    }, []);

    const chartData = [
        { browser: "safari", visitors: user!.loan_credit, fill: "var(--color-safari)" },
    ]

    const chartConfig = {
        visitors: {
            label: "Visitors",
        },
        safari: {
            label: "Safari",
            color: "hsl(var(--chart-2))",
        },
    } satisfies ChartConfig

    return (
        <div>

            <div className='mb-[30px]'>
                <div className='text-2xl font-pbold'>Loans</div>
                <div className='font-plight text-sm'>Earn interest on Loans</div>
            </div>

            <div className='grid grid-cols-1 md:grid-cols-2 mb-[100px]'>
                {!isFetching ?
                    <>
                        {
                            loans.length > 0 ?
                                <div>
                                    <div className='flex justify-end items-center mb-6'>
                                        <LoanInitiate setLoans={setLoans}>
                                            <Button className='mb-5 bg-green-600 text-white'>Request loan</Button>
                                        </LoanInitiate>
                                    </div>

                                    <div className='space-y-4'>
                                        {
                                            loans.map((s, i) => (
                                                <div key={i} className='px-8 py-5 rounded-xl border shadow-md'>
                                                    <div className='flex justify-between gap-3 items-center'>
                                                        <div className='mb-4 text-xs inline-block px-3 py-1 rounded-2xl font-pregular bg-green-500/40'>Loan</div>
                                                        <div>
                                                            <Button onClick={() => handleRepayLoan(s.id)} className='text-xs' variant={'link'} size={'sm'}>Repay</Button>
                                                        </div>
                                                    </div>
                                                    <div className='space-y-4'>
                                                        <div className='space-y-1'>
                                                            <div className='font-plight text-xs'>Type</div>
                                                            <div className='text-sm'>{s.type}</div>
                                                        </div>
                                                        <div className='flex items-center gap-3'>
                                                            <div className='space-y-1'>
                                                                <div className='font-plight text-xs'>Amount</div>
                                                                <div className='text-sm'>{numberFormat(s.amount.toString())}</div>
                                                            </div>
                                                            <div className='space-y-1'>
                                                                <div className='font-plight text-xs'>Interest rate</div>
                                                                <div className='text-sm'>{s.interest_rate}%</div>
                                                            </div>
                                                        </div>
                                                        <div className='space-y-1'>
                                                            <div className='font-plight text-xs'>Expires</div>
                                                            <div className='text-sm'>{formatDate(s.expires_at)}</div>
                                                        </div>
                                                        <div className='space-y-1'>
                                                            <div className='font-plight text-xs'>Account</div>
                                                            <div className='text-sm'>{s.account.name.toUpperCase()} {upperCaseFirst(s.account.type)}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                                :
                                <div>
                                    <Card className="w-full md:w-[280px] mb-[50px] relative overflow-hidden bg-cover">
                                        <CardHeader>
                                            <CardTitle className='font-pbold'>$0.00</CardTitle>
                                            <CardDescription>Loans</CardDescription>
                                        </CardHeader>
                                        <CardFooter className='relative'>
                                            {/* <div className="absolute inset-0 bg-gradient-to-b from-transparent to-green-200 opacity-50 rounded-full"></div> */}
                                            <Button className='w-full relative bg-green-600' disabled>Repay</Button>
                                        </CardFooter>
                                    </Card>
                                    <div className='flex justify-center items-center'>
                                        <div>
                                            <div className='font-psemibold text-lg'>Discover</div>
                                            <div className='font-plight mb-5'>Get the financing you need with our competitive loan rates. Apply for a loan online or visit one of our branches. We offer a variety of loan products to suit your needs.</div>

                                            <LoanInitiate setLoans={setLoans}>
                                                <Button className='mb-5 bg-green-600 text-white'>Request for loan</Button>
                                            </LoanInitiate>
                                        </div>
                                    </div>
                                </div>
                        }
                    </> :
                    <div className='w-full h-[300px] md:h-[500px] flex justify-center items-center animate-spin'>
                        <div>
                            <CgSpinner className='w-10 h-10' />
                        </div>
                    </div>
                }
                <div className='flex justify-center'>
                    <div>
                        <Card className="flex flex-col">
                            <CardHeader className="items-center pb-0">
                                <CardTitle>Loan score</CardTitle>
                                <CardDescription>based on credit</CardDescription>
                            </CardHeader>
                            <CardContent className="flex-1 pb-0">
                                <ChartContainer
                                    config={chartConfig}
                                    className="mx-auto aspect-square max-h-[250px]"
                                >
                                    <RadialBarChart
                                        data={chartData}
                                        startAngle={0}
                                        endAngle={250}
                                        innerRadius={80}
                                        outerRadius={110}
                                    >
                                        <PolarGrid
                                            gridType="circle"
                                            radialLines={false}
                                            stroke="none"
                                            className="first:fill-muted last:fill-background"
                                            polarRadius={[86, 74]}
                                        />
                                        <RadialBar dataKey="visitors" background cornerRadius={10} />
                                        <PolarRadiusAxis tick={false} tickLine={false} axisLine={false}>
                                            <Label
                                                content={({ viewBox }) => {
                                                    if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                                                        return (
                                                            <text
                                                                x={viewBox.cx}
                                                                y={viewBox.cy}
                                                                textAnchor="middle"
                                                                dominantBaseline="middle"
                                                            >
                                                                <tspan
                                                                    x={viewBox.cx}
                                                                    y={viewBox.cy}
                                                                    className="fill-foreground text-4xl font-bold"
                                                                >
                                                                    {chartData[0].visitors.toLocaleString()}
                                                                </tspan>
                                                                <tspan
                                                                    x={viewBox.cx}
                                                                    y={(viewBox.cy || 0) + 24}
                                                                    className="fill-muted-foreground"
                                                                >
                                                                    score
                                                                </tspan>
                                                            </text>
                                                        )
                                                    }
                                                }}
                                            />
                                        </PolarRadiusAxis>
                                    </RadialBarChart>
                                </ChartContainer>
                            </CardContent>
                            <CardFooter className="flex-col gap-2 text-sm">
                                <div className="flex items-center gap-2 font-medium leading-none">
                                    up to {numberFormat(user!.max_loan.toString())} <TrendingUp className="h-4 w-4" />
                                </div>
                                <div className="leading-none text-muted-foreground">
                                    Loan score determines loanable amount
                                </div>
                            </CardFooter>
                        </Card>
                    </div>
                </div>
            </div>

            {/* <div className='w-full'>
                <div className='w-full h-[200px] bg-no-repeat bg-cover bg-center rounded-lg' style={{ backgroundImage: "url('/assets/sap500.png')" }}>
                    <div className='w-full h-[200px] bg-cyan-900/80 rounded-lg relative px-10 py-5'>
                        <div className='grid grid-cols-1 md:grid-cols-2'>
                            <div className='text-white space-y-2'>
                                <div className='font-plight text-2xl'>Invest in S&P 500 stocks</div>
                                <div className='text-sm font-pregular'> S&P 500 is a stock market index tracking the stock performance of 500 of the largest companies listed on stock exchanges in the United States</div>
                                <Button className='mb-5 bg-green-600 text-white'>Get started</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    )
}

export default Loans