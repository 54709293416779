
import React, { useState } from 'react'

import { Button } from "@/_components/ui/button"
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from "@/_components/ui/card"
import { Input } from "@/_components/ui/input"
import { Label } from "@/_components/ui/label"
import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from "@/_components/ui/tabs"
import { useApp } from '@/contexts/appContext'
import { upperCaseFirst } from '@/utils/helpers'
import { toast } from '@/_components/ui/use-toast'
import http from '@/lib/http'
import { CgSpinner } from 'react-icons/cg'

function Profile() {

    const {user} = useApp();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [data, setData] = useState({
        old: "",
        password: ""
    })

    async function handlePasswordChange() {
        if (window.confirm("Ae you sure")) {
            setIsSubmitting(true);
            try {
                const resp = await http().post('/user/security/pass', data);
                toast({
                    title: "Success",
                    description: resp.data.message,
                    variant: 'default',
                    duration: 3000
                });
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            } catch (error) {
                console.log(error)
                toast({
                    title: "Error",
                    description: (error as any).response.data.message,
                    variant: 'destructive',
                    duration: 3000
                })
            } finally {
                setIsSubmitting(false)
            }
        }
    }

    function isChangeButtonValid() {
        if (data.old.length > 0 && data.password.length > 0 && !isSubmitting) {
            return true;
        }
        return false;
    }

    return (
        <div>
            <div className=''>
                <div className='text-2xl font-pbold'>Manage Profile</div>
                <div className='font-plight text-sm'>Manage account and account security</div>
            </div>
            <div>
                <Tabs defaultValue="account" className="w-full mt-[50px]">
                    <TabsList className="grid w-full grid-cols-2">
                        <TabsTrigger value="account">Account</TabsTrigger>
                        <TabsTrigger value="password">Password</TabsTrigger>
                    </TabsList>
                    <TabsContent value="account">
                        <Card>
                            <CardHeader>
                                <CardTitle>Account</CardTitle>
                                <CardDescription>
                                    Make changes to your account here. Click save when you're done.
                                </CardDescription>
                            </CardHeader>
                            <CardContent className="space-y-2">
                                <div className="space-y-1">
                                    <Label htmlFor="name">Name</Label>
                                    <Input id="name" defaultValue={`${upperCaseFirst(user!.firstname)} ${user!.middlename ? upperCaseFirst(user!.middlename) : ''} ${upperCaseFirst(user!.lastname)}`} readOnly />
                                </div>
                                <div className="space-y-1">
                                    <Label htmlFor="username">Email</Label>
                                    <Input id="username" defaultValue={user!.email} />
                                </div>
                                <div className="space-y-1">
                                    <Label htmlFor="username">Gender</Label>
                                    <Input id="username" defaultValue={user!.gender} />
                                </div>
                                <div className="space-y-1">
                                    <Label htmlFor="username">Country</Label>
                                    <Input id="username" defaultValue={user!.country} />
                                </div>
                                <div className="space-y-1">
                                    <Label htmlFor="username">Date of birth</Label>
                                    <Input id="username" defaultValue={user!.dob} />
                                </div>
                            </CardContent>
                            {/* <CardFooter>
                                <Button>Save changes</Button>
                            </CardFooter> */}
                        </Card>
                    </TabsContent>
                    <TabsContent value="password">
                        <Card>
                            <CardHeader>
                                <CardTitle>Password</CardTitle>
                                <CardDescription>
                                    Change your password here. After saving, you'll be logged out.
                                </CardDescription>
                            </CardHeader>
                            <CardContent className="space-y-2">
                                <div className="space-y-1">
                                    <Label htmlFor="current">Current password</Label>
                                    <Input value={data.old} onChange={(e) => setData(d => ({...d, old: e.target.value}))} id="current" type="password" />
                                </div>
                                <div className="space-y-1">
                                    <Label htmlFor="new">New password</Label>
                                    <Input value={data.password} onChange={(e) => setData(d => ({...d, password: e.target.value}))} id="new" type="password" />
                                </div>
                            </CardContent>
                            <CardFooter>
                                <Button onClick={() => handlePasswordChange()} disabled={!isChangeButtonValid()}>
                                    {
                                        !isSubmitting ?
                                        <div>Save password</div>:
                                        <CgSpinner className='animate-spin' />
                                    }
                                </Button>
                            </CardFooter>
                        </Card>
                    </TabsContent>
                </Tabs>

                <div className='flex gap-10 '>
                    <div></div>
                </div>
            </div>
        </div>
    )
}

export default Profile