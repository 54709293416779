import React from 'react'
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from "@/_components/ui/card"

import {
    Carousel,
    CarouselContent,
    CarouselItem,
    CarouselNext,
    CarouselPrevious,
} from "@/_components/ui/carousel"

import { useApp } from '@/contexts/appContext';
import { numberFormat } from '@/utils/helpers';

type AccountCarouselType = {
    currency?: 'USD' | 'EUR'
}

function AccountCarousel({currency = 'USD'}: AccountCarouselType) {    

    const {user} = useApp();

    // const getUsdAccounts = useMemo(() => {
    //     const accounts = user!.accounts;
    //     return accounts.filter((a) => a.name === "usd");
    // }, [user]);

    // const getEurAccounts = useMemo(() => {
    //     const accounts = user!.accounts;
    //     return accounts.filter((a) => a.name === "eur");
    // }, [user]);

    const currencySymbol = currency.toLowerCase() === "usd" ? '$' : '€';

    function getAccountByType(type: "checking" | "savings") {
        const accounts_ = user!.accounts;
        const accounts = accounts_.filter((a) => a.name === currency.toLowerCase());
        return accounts.filter((a) => a.type === type)[0];
    }

    return (
        <Carousel className="w-[88%] max-w-[88%] md:max-w-[280px]">
            <CarouselContent>

                <CarouselItem>
                    <Card className="w-[100%] md:w-full relative overflow-hidden md:mb-5 bg-cover"
                        // style={{ backgroundImage: "url('/assets/pattern3.jpg')" }}
                    >
                        <CardHeader>
                            <CardTitle className='font-pbold'>{currencySymbol} {numberFormat(getAccountByType("savings").available_balance)}</CardTitle>
                            <CardDescription>{currency} Savings</CardDescription>
                        </CardHeader>
                        <CardContent>
                            <div className='relative h-[50px]'>
                                <img className='absolute -right-[40px]' src="/assets/cc.png" alt="" />
                                <div className='absolute right-0 text-white py-5 font-extrabold'>
                                    .... .... {getAccountByType("savings").account_number.slice(-4)}
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                </CarouselItem>


                <CarouselItem>
                    <Card className="w-[100%] md:w-full relative overflow-hidden md:mb-5 bg-cover"
                        // style={{ backgroundImage: "url('/assets/pattern3.jpg')" }}
                    >
                        <CardHeader>
                            <CardTitle className='font-pbold'>{currencySymbol} {numberFormat(getAccountByType("checking").available_balance)}</CardTitle>
                            <CardDescription>{currency} Checking</CardDescription>
                        </CardHeader>
                        <CardContent>
                            <div className='relative h-[50px]'>
                                <img className='absolute -right-[40px]' src="/assets/cc.png" alt="" />
                                <div className='absolute right-0 text-white py-5 font-extrabold'>
                                .... .... {getAccountByType("checking").account_number.slice(-4)}
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                </CarouselItem>
            </CarouselContent>
            <CarouselPrevious />
            <CarouselNext />
        </Carousel>
    )
}

export default AccountCarousel;