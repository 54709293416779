
import { Button } from '@/_components/ui/button';
import { Input } from '@/_components/ui/input';
import { Label } from '@/_components/ui/label';
import { toast } from '@/_components/ui/use-toast';
import CustomFileInput from '@/components/CustomFileInput';
import http from '@/lib/http';
import React, { useEffect, useState } from 'react'
import { CgSpinner } from 'react-icons/cg';
import { useNavigate, useParams } from 'react-router-dom';
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
} from "@/_components/ui/select"

function FundAccountCrypto() {

    const { id } = useParams();
    const [isFetching, setIsFetching] = useState(false);
    const [isFetchingData, setIsFetchingData] = useState(true);
    const [addresses, setAddresses] = useState<{
        id: number,
        network: string,
        currency: string,
        address: string,
        logo: string
    }[]>([])
    const navigate = useNavigate();

    const [form, setForm] = useState<{
        account_id: string,
        proof: File | null,
        address_id: string,
        amount: string
    }>({
        account_id: id!,
        proof: null,
        address_id: "",
        amount: ""
    });

    async function fetchAddresses() {
        try {
            const resp = await http().get('/user/payments/crypto/addresses');
            setAddresses(resp.data);
            console.log(resp.data);
        } catch (error) {
            console.log(error)
        } finally {
            setIsFetchingData(false);
        }
    }

    async function handleDepositCrypto() {
        setIsFetching(true);
        try {
            const formData = new FormData();
            formData.append('account_id', form.account_id);
            formData.append('proof', form.proof!);
            formData.append('network', getAddressInfo().network);
            formData.append('currency', getAddressInfo().currency);
            formData.append('address', getAddressInfo().address);
            formData.append('amount', form.amount);
            const resp = await http().post('/user/fund/crypto', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            toast({
                title: "Success",
                description: resp.data.message,
                variant: 'default',
                duration: 3000
            });
            navigate('/d/accounts');
        } catch (error) {
            console.log(error);
            toast({
                title: "Error",
                description: (error as any).response.data.message,
                variant: 'destructive',
                duration: 3000
            });
        }
    }

    function isSubmitButtonValid() {
        if (form.proof && form.address_id.length > 0 && form.amount.length > 0) {
            return true;
        }
        return false;
    }

    useEffect(() => {
        fetchAddresses();
    }, []);

    function getAddressInfo() {
        return addresses.filter((e) => e.id.toString() === form.address_id)[0];
    }

    return (
        <div>

            <div className='mb-[60px]'>
                <div className='text-2xl font-pbold'>Deposit Crypto</div>
                <div className='font-plight text-sm'>Fund account by depositing crypto currencies</div>
            </div>

            <div className='space-y-5'>
                <div className="grid w-full max-w-sm items-center gap-1.5 mb-6">
                    <Label htmlFor="amount">Amount</Label>
                    <Input value={form.amount} onChange={(e) => setForm(f => ({ ...f, amount: e.target.value }))} className='border-neutral-400/60 dark:border-neutral-400/20' type="number" id="amount" placeholder="Enter amount" />
                </div>
            </div>

            <div className='space-y-5'>
                <div className="grid w-full max-w-sm items-center gap-1.5 mb-6">
                    <Label htmlFor="address_id" className='flex items-center gap-3'>
                        <div className=''>Currency</div>
                        {isFetchingData && <CgSpinner className='animate-spin' />}
                    </Label>
                    <Select value={form.address_id} onValueChange={(e) => setForm(f => ({ ...f, address_id: e }))}>
                        <SelectTrigger className="w-[280px]">
                            <SelectValue placeholder="Select currency" />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectGroup>
                                <SelectLabel>Currencies</SelectLabel>
                                {
                                    addresses.map((a, i) => (
                                        <SelectItem key={i} value={a.id.toString()}>
                                            <div className='flex items-center gap-2'>
                                                {/* <img src={a.logo} className='w-4' alt="" /> */}
                                                <div>{a.currency} - {a.network}</div>
                                            </div>
                                        </SelectItem>
                                    ))
                                }
                            </SelectGroup>
                        </SelectContent>
                    </Select>
                </div>
            </div>

            {form.address_id.length > 0 && <div className='px-8 py-5 border rounded-xl space-y-4 mb-6'>
                <div>
                    <div className='font-pbold text-lg'>Payment information</div>
                    <div className='font-plight text-sm'>Pay to the address below and upload proof of payment</div>
                </div>
                <div className='space-y-1'>
                    <div className='text-xs'>Currency</div>
                    <div>{getAddressInfo().currency}</div>
                </div>
                <div className='space-y-1'>
                    <div className='text-xs'>Address</div>
                    <div>{getAddressInfo().address}</div>
                </div>
                <div className='space-y-1'>
                    <div className='text-xs'>Network</div>
                    <div>{getAddressInfo().network}</div>
                </div>
            </div>}

            <div className='space-y-5'>
                <div className="grid w-full max-w-sm items-center gap-1.5 mb-6">
                    <Label htmlFor="proof">Upload proof</Label>
                    <CustomFileInput onChange={(e) => setForm(f => ({ ...f, proof: e }))} />
                </div>
            </div>

            <Button onClick={() => handleDepositCrypto()} className='mt-5 bg-green-600 text-white w-[200px] mb-[100px]' variant="outline" disabled={!isSubmitButtonValid()}>
                {!isFetching ?
                    <div>Submit</div> :
                    <CgSpinner className='animate-spin' />
                }
            </Button>
            
        </div>
    )
}

export default FundAccountCrypto