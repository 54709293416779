
import React, { useEffect, useState } from 'react'

import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardTitle,
} from "@/_components/ui/card"
import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from "@/_components/ui/tabs"
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/_components/ui/table"
import { BsSend } from "react-icons/bs";
import { MdOutlineCallReceived } from "react-icons/md";
import { useApp } from '@/contexts/appContext';
import { formatDate, numberFormat } from '@/utils/helpers';
import SwapFunds from '@/components/SwapFunds';
import http from '@/lib/http';
import { ITransaction } from '@/types/global.types';
import { LuClock3 } from "react-icons/lu";
import { PiEmptyLight } from "react-icons/pi";
import { Skeleton } from '@/_components/ui/skeleton';
import { useNavigate } from 'react-router-dom';
import PrintStatement from '@/components/PrintStatement';

function Accounts() {

    const { user } = useApp();
    const [isFetchingData, setIsFetchingData] = useState(true);
    const [activeAccount, setActiveAccount] = useState(getAccountByType("usd", "savings").id);
    const [transactions, setTransactions] = useState<ITransaction[]>([]);

    const navigate = useNavigate();

    function getAccountByType(currency: "usd" | "eur", type: "checking" | "savings") {
        const accounts_ = user!.accounts;
        const accounts = accounts_.filter((a) => a.name === currency.toLowerCase());
        return accounts.filter((a) => a.type === type)[0];
    }

    async function getTransactions() {
        setIsFetchingData(true)
        try {
            const resp = await http().get(`/user/transactions/${activeAccount}`);
            setTransactions(resp.data.data)
        } catch (error) {
            console.log(error)
        } finally {
            setIsFetchingData(false);
        }
    }

    useEffect(() => {
        getTransactions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeAccount])

    return (
        <div>
            <div className='mb-[30px]'>
                <div className='text-2xl font-pbold'>Manage Accounts</div>
                <div className='font-plight text-sm'>Access & manage your account efficiently, view account, swap, fund.</div>
            </div>
            <div>
                <Tabs defaultValue="account" className="mb-[50px]">
                    <TabsList className="grid grid-cols-2 w-full md:w-[400px]">
                        <TabsTrigger value="account" className='flex gap-2 items-center'>
                            <img src="/assets/flags/usd.svg" className='w-4' alt="" />
                            <div>USD</div>
                        </TabsTrigger>
                        <TabsTrigger value="password" className='flex gap-2 items-center'>
                            <img src="/assets/flags/eur.svg" className='w-4' alt="" />
                            <div>EUR</div>
                        </TabsTrigger>
                    </TabsList>
                    <TabsContent value="account">
                        <div className='grid grid-cols-1 md:flex gap-5 mt-6'>
                            <Card className={`w-full md:w-[300px] cursor-pointer ${activeAccount === getAccountByType("usd", "savings").id && 'border-green-500'}`} onClick={() => setActiveAccount(getAccountByType("usd", "savings").id)}>
                                <CardContent className="space-y-2 pt-8">
                                    <CardTitle>${numberFormat(getAccountByType("usd", "savings").available_balance)}</CardTitle>
                                    <CardDescription>
                                        Savings
                                    </CardDescription>
                                    <div className=''>
                                        <div className='font-pregular text-opacity-10 text-primary text-sm'>.... .... {getAccountByType("usd", "savings").account_number.slice(-4)}</div>
                                    </div>
                                </CardContent>
                                <CardFooter>
                                    <div className='flex gap-5 items-center'>
                                        <div onClick={() => navigate(`/d/payments`)} className='bg-accent border w-[55px] h-[55px] pt-1 rounded-full flex justify-center items-center cursor-pointer'>
                                            <center>
                                                <BsSend className='w-4 h-4' />
                                                <div className='text-xs'>Pay</div>
                                            </center>
                                        </div>

                                        <div onClick={() => navigate(`/d/accounts/${getAccountByType("usd", "savings").id}/fund`)} className='bg-accent border w-[55px] h-[55px] pt-1 rounded-full flex justify-center items-center cursor-pointer'>
                                            <center>
                                                <MdOutlineCallReceived className='w-4 h-4' />
                                                <div className='text-xs'>Fund</div>
                                            </center>
                                        </div>

                                        <SwapFunds from={getAccountByType("usd", "savings").id} />
                                    </div>
                                </CardFooter>
                            </Card>

                            <Card className={`w-full md:w-[300px] cursor-pointer ${activeAccount === getAccountByType("usd", "checking").id && 'border-green-500'}`} onClick={() => setActiveAccount(getAccountByType("usd", "checking").id)}>
                                <CardContent className="space-y-2 pt-8">
                                    <CardTitle>${numberFormat(getAccountByType("usd", "checking").available_balance)}</CardTitle>
                                    <CardDescription>
                                        Checking
                                    </CardDescription>
                                    <div className=''>
                                        <div className='font-pregular text-opacity-10 text-primary text-sm'>.... .... {getAccountByType("usd", "checking").account_number.slice(-4)}</div>
                                    </div>
                                </CardContent>
                                <CardFooter>
                                    <div className='flex gap-5 items-center'>
                                        <div onClick={() => navigate(`/d/payments`)} className='bg-accent border w-[55px] h-[55px] pt-1 rounded-full flex justify-center items-center cursor-pointer'>
                                            <center>
                                                <BsSend className='w-4 h-4' />
                                                <div className='text-xs'>Pay</div>
                                            </center>
                                        </div>

                                        <div onClick={() => navigate(`/d/accounts/${getAccountByType("usd", "checking").id}/fund`)} className='bg-accent border w-[55px] h-[55px] pt-1 rounded-full flex justify-center items-center cursor-pointer'>
                                            <center>
                                                <MdOutlineCallReceived className='w-4 h-4' />
                                                <div className='text-xs'>Fund</div>
                                            </center>
                                        </div>

                                        <SwapFunds from={getAccountByType("usd", "checking").id} />
                                    </div>
                                </CardFooter>
                            </Card>
                        </div>
                    </TabsContent>
                    <TabsContent value="password">
                        <div className='grid grid-cols-1 md:flex gap-5 mt-6'>
                            <Card className={`w-full md:w-[300px] cursor-pointer ${activeAccount === getAccountByType("eur", "savings").id && 'border-green-500'}`} onClick={() => setActiveAccount(getAccountByType("eur", "savings").id)}>
                                <CardContent className="space-y-2 pt-8">
                                    <CardTitle>€{numberFormat(getAccountByType("eur", "savings").available_balance)} </CardTitle>
                                    <CardDescription>
                                        Savings
                                    </CardDescription>
                                    <div className=''>
                                        <div className='font-pregular text-opacity-10 text-primary text-sm'>.... .... {getAccountByType("eur", "savings").account_number.slice(-4)}</div>
                                    </div>
                                </CardContent>
                                <CardFooter>
                                    <div className='flex gap-5 items-center'>
                                        <div onClick={() => navigate(`/d/payments`)} className='bg-accent border w-[55px] h-[55px] pt-1 rounded-full flex justify-center items-center cursor-pointer'>
                                            <center>
                                                <BsSend className='w-4 h-4' />
                                                <div className='text-xs'>Pay</div>
                                            </center>
                                        </div>

                                        <div onClick={() => navigate(`/d/accounts/${getAccountByType("eur", "savings").id}/fund`)} className='bg-accent border w-[55px] h-[55px] pt-1 rounded-full flex justify-center items-center cursor-pointer'>
                                            <center>
                                                <MdOutlineCallReceived className='w-4 h-4' />
                                                <div className='text-xs'>Fund</div>
                                            </center>
                                        </div>

                                        <SwapFunds from={getAccountByType("eur", "savings").id} />
                                    </div>
                                </CardFooter>
                            </Card>

                            <Card className={`w-full md:w-[300px] cursor-pointer ${activeAccount === getAccountByType("eur", "checking").id && 'border-green-500'}`} onClick={() => setActiveAccount(getAccountByType("eur", "checking").id)}>
                                <CardContent className="space-y-2 pt-8">
                                    <CardTitle>€{numberFormat(getAccountByType("eur", "checking").available_balance)}</CardTitle>
                                    <CardDescription>
                                        Checking
                                    </CardDescription>
                                    <div className=''>
                                        <div className='font-pregular text-opacity-10 text-primary text-sm'>.... .... {getAccountByType("eur", "checking").account_number.slice(-4)}</div>
                                    </div>
                                </CardContent>
                                <CardFooter>
                                    <div className='flex gap-5 items-center'>
                                        <div onClick={() => navigate(`/d/payments`)} className='bg-accent border w-[55px] h-[55px] pt-1 rounded-full flex justify-center items-center cursor-pointer'>
                                            <center>
                                                <BsSend className='w-4 h-4' />
                                                <div className='text-xs'>Pay</div>
                                            </center>
                                        </div>

                                        <div onClick={() => navigate(`/d/accounts/${getAccountByType("eur", "checking").id}/fund`)} className='bg-accent border w-[55px] h-[55px] pt-1 rounded-full flex justify-center items-center cursor-pointer'>
                                            <center>
                                                <MdOutlineCallReceived className='w-4 h-4' />
                                                <div className='text-xs'>Fund</div>
                                            </center>
                                        </div>

                                        <SwapFunds from={getAccountByType("eur", "checking").id} />
                                    </div>
                                </CardFooter>
                            </Card>
                        </div>
                    </TabsContent>
                </Tabs>

                <div className=''>
                    <div className='flex justify-between gap-5 items-center mb-5'>
                        <div className='font-pmedium'>Transactions</div>
                        <div>
                            <PrintStatement account={activeAccount} />
                        </div>
                    </div>
                    <div>
                        {!isFetchingData ?
                            transactions.length > 0 ?
                                <Table>
                                    <TableHeader>
                                        <TableRow>
                                            <TableHead className="w-[100px]"><LuClock3 className='w-5 h-5' /></TableHead>
                                            <TableHead>Narration</TableHead>
                                            <TableHead className="text-left">Amount</TableHead>
                                            <TableHead className='text-right'>Status</TableHead>
                                        </TableRow>
                                    </TableHeader>
                                    <TableBody>
                                        {transactions.map((transaction, i) => (
                                            <TableRow key={i}>
                                                <TableCell className="font-medium">{formatDate(transaction.created_at)}</TableCell>
                                                <TableCell>{transaction.narration}</TableCell>
                                                <TableCell>{transaction.amount}</TableCell>
                                                <TableCell className="text-right">{transaction.status === 1 ? "Pending" : transaction.status === 2 ? "Successful" : "Declined"}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                                :
                                <div className='w-full h-[300px] flex justify-center items-center'>
                                    <div className='space-y-2'>
                                        <center><PiEmptyLight className='w-6 h-6' /></center>
                                        <div>No transaction</div>
                                    </div>
                                </div>
                            :
                            <Table>
                                <TableHeader>
                                    <TableRow>
                                        <TableHead className="w-[100px]"><LuClock3 className='w-5 h-5' /></TableHead>
                                        <TableHead>Narration</TableHead>
                                        <TableHead className="text-left">Amount</TableHead>
                                        <TableHead className='text-right'>Status</TableHead>
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {Array.from(Array(6)).map((_, i) => (
                                        <TableRow key={i}>
                                            <TableCell className="font-medium"><Skeleton className='h-[25px] w-[100px]' /></TableCell>
                                            <TableCell><Skeleton className='h-[25px] w-full' /></TableCell>
                                            <TableCell><Skeleton className='h-[25px] w-full' /></TableCell>
                                            <TableCell className="text-right"><Skeleton className='h-[25px] w-full' /></TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        }
                    </div>
                </div>
            </div>

            <div className='py-10'></div>
        </div>
    )
}

export default Accounts