
import React from 'react'

import {
    Card,
    CardContent,
    CardDescription,
    CardTitle,
} from "@/_components/ui/card"

import { BsSend } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';

function Payments() {

    const navigate = useNavigate();

    return (
        <div>
            <div className='mb-[30px]'>
                <div className='text-2xl font-pbold'>Make payment</div>
                <div className='font-plight text-sm'>Send funds across banks. pay bills and more</div>
            </div>

            <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-5'>
                <Card className='w-full cursor-pointer hover:bg-accent' onClick={() => navigate('/d/payments/transfer/global')}>
                    <CardContent className="flex items-center justify-between gap-5 pt-8">
                        <div className='space-y-2'>
                            <CardTitle>Send Global</CardTitle>
                            <CardDescription>
                                Send to bank accounts or mobile money
                            </CardDescription>
                        </div>
                        <div>
                            <div className='bg-accent border w-[55px] h-[55px] pt-1 rounded-full flex justify-center items-center cursor-pointer'>
                                <center>
                                    <BsSend className='w-4 h-4' />
                                </center>
                            </div>
                        </div>
                    </CardContent>
                </Card>
                
                <Card className='w-full cursor-pointer hover:bg-accent' onClick={() => navigate('/d/payments/transfer/internal')}>
                    <CardContent className="flex items-center justify-between gap-5 pt-8">
                        <div className='space-y-2'>
                            <CardTitle>Send Internal</CardTitle>
                            <CardDescription>
                                Swap/convert funds between accounts
                            </CardDescription>
                        </div>
                        <div>
                            <div className='bg-accent border w-[55px] h-[55px] pt-1 rounded-full flex justify-center items-center cursor-pointer'>
                                <center>
                                    <BsSend className='w-4 h-4' />
                                </center>
                            </div>
                        </div>
                    </CardContent>
                </Card>
                
                <Card className='w-full cursor-pointer hover:bg-accent' onClick={() => navigate('/d/payments/bill')}>
                    <CardContent className="flex items-center justify-between gap-5 pt-8">
                        <div className='space-y-2'>
                            <CardTitle>Bill payment</CardTitle>
                            <CardDescription>
                                Pay bills easily with your account
                            </CardDescription>
                        </div>
                        <div>
                            <div className='bg-accent border w-[55px] h-[55px] pt-1 rounded-full flex justify-center items-center cursor-pointer'>
                                <center>
                                    <BsSend className='w-4 h-4' />
                                </center>
                            </div>
                        </div>
                    </CardContent>
                </Card>
                
                <Card className='w-full cursor-not-allowed hover:bg-accent text-foreground/50' onClick={() => navigate('/d/payments/transfer/global')}>
                    <CardContent className="flex items-center justify-between gap-5 pt-8">
                        <div className='space-y-2'>
                            <CardTitle>Invoice</CardTitle>
                            <CardDescription>
                                Settle business invoices easily in few clicks
                            </CardDescription>
                        </div>
                        <div>
                            <div className='bg-accent border w-[55px] h-[55px] pt-1 rounded-full flex justify-center items-center cursor-pointer'>
                                <center>
                                    <BsSend className='w-4 h-4' />
                                </center>
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </div>
        </div>
    )
}

export default Payments