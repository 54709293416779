"use client"
import { Pie, PieChart } from "recharts"

import {
    Card,
    CardContent
} from "@/_components/ui/card"
import {
    ChartConfig,
    ChartContainer,
    ChartTooltip,
    ChartTooltipContent,
} from "@/_components/ui/chart"
import { useApp } from "@/contexts/appContext"

export const description = "A donut chart with text"

const chartConfig = {
    visitors: {
        label: "Visitors",
    },
    chrome: {
        label: "USD Savings",
        color: "hsl(var(--chart-1))",
    },
    safari: {
        label: "USD Checking",
        color: "hsl(var(--chart-2))",
    },
    firefox: {
        label: "EUR Savings",
        color: "hsl(var(--chart-3))",
    },
    edge: {
        label: "EUR Checking",
        color: "hsl(var(--chart-5))",
    },
    // other: {
    //     label: "Investments",
    //     color: "hsl(var(--chart-4))",
    // },
} satisfies ChartConfig

function OverviewBalanceChart() {  

    const {user} = useApp();    

    function getAccountValue(name: "usd" | "eur", type: "savings" | "checking") {
        const accounts = user!.accounts;
        return parseFloat(accounts.filter((a) => a.name === name && a.type === type)[0].available_balance)
    }

    const chartData = [
        { account: "chrome", value: getAccountValue("usd", "savings"), fill: "var(--color-chrome)" },
        { account: "safari", value: getAccountValue("usd", "checking"), fill: "var(--color-safari)" },
        { account: "firefox", value: getAccountValue("eur", "savings"), fill: "var(--color-firefox)" },
        { account: "edge", value: getAccountValue("eur", "checking"), fill: "var(--color-edge)" },
        // { account: "other", value: 190, fill: "var(--color-other)" },
    ]

    return (
        <Card className="flex flex-col border-none">
            <CardContent className="flex-1 pb-0 px-1">
                <div className="flex items-center gap-2 md:gap-5">
                    <div className="w-[90px] md:w-[150px]">
                        <ChartContainer
                            config={chartConfig}
                            className="mx-auto aspect-square max-h-[150px]"
                        >
                            <PieChart>
                                <ChartTooltip
                                    cursor={false}
                                    content={<ChartTooltipContent hideLabel />}
                                />
                                <Pie
                                    data={chartData}
                                    dataKey="value"
                                    nameKey="account"
                                    innerRadius={18}
                                    strokeWidth={5}
                                >
                                </Pie>
                            </PieChart>
                        </ChartContainer>
                    </div>

                    <div className="flex-1">

                        <div className="flex items-center gap-3 mb-2">
                            <div className="font-pmedium text-sm border-b-4 border-b-green-700">Overview</div>
                        </div>

                        <div className="grid md:flex grid-cols-1 md:grid-cols-2 gap-1 md:gap-3 mb-1 md:mb-0">

                            <div className="flex items-center gap-2 md:gap-3">
                                <div className="flex items-center gap-1">
                                    <div className="w-[10px] h-[10px] bg-[hsl(var(--chart-1))]"></div>
                                    <div className="text-[11px] md:text-[13px] mt-0.5 font-psemibold">USD Savings</div>
                                </div>
                                <div className="flex items-center gap-1">
                                    <div className="w-[10px] h-[10px] bg-[hsl(var(--chart-2))]"></div>
                                    <div className="text-[11px] md:text-[13px] mt-0.5 font-psemibold">USD Checking</div>
                                </div>
                            </div>

                            <div className="flex items-center gap-2 md:gap-3">
                                <div className="flex items-center gap-1">
                                    <div className="w-[10px] h-[10px] bg-[hsl(var(--chart-3))]"></div>
                                    <div className="text-[11px] md:text-[13px] mt-0.5 font-psemibold">EUR Savings</div>
                                </div>
                                <div className="flex items-center gap-1">
                                    <div className="w-[10px] h-[10px] bg-[hsl(var(--chart-5))]"></div>
                                    <div className="text-[11px] md:text-[13px] mt-0.5 font-psemibold">EUR Checking</div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="flex items-center gap-1">
                            <div className="w-[10px] h-[10px] bg-[hsl(var(--chart-4))]"></div>
                            <div className="text-[11px] md:text-[13px] mt-0.5 font-psemibold">Investments</div>
                        </div> */}
                    </div>
                </div>
            </CardContent>
        </Card>
    )
}

export default OverviewBalanceChart;