import React, { useState } from 'react'
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/_components/ui/dialog"
import { Label } from '@/_components/ui/label';
import { Button } from '@/_components/ui/button';
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
} from "@/_components/ui/select"
import { useApp } from '@/contexts/appContext';
import { CgSpinner } from 'react-icons/cg';
import { toast } from '@/_components/ui/use-toast';
import http from '@/lib/http';
import { ICard } from '@/types/global.types';

function CreateCard({children, setCards}: {children: React.ReactNode, setCards: React.Dispatch<React.SetStateAction<ICard[]>>}) {

    const { user } = useApp();
    const [isOpen, setIsOpen] = useState(false);
    const [isCreating, setIsCreating] = useState(false);
    const [data, setData] = useState({
        account_id: "",
        type: ""
    });

    function getAccountByType(currency: "usd" | "eur", type: "checking" | "savings") {
        const accounts_ = user!.accounts;
        const accounts = accounts_.filter((a) => a.name === currency.toLowerCase());
        return accounts.filter((a) => a.type === type)[0];
    }

    async function handleCreate() {
        setIsCreating(true);
        try {
            const resp = await http().post('/user/cards/create', data);
            setCards((u) => ([...u, resp.data.data]));
            // setCards(resp.data.data);
            console.log(resp.data.data)
            toast({
                title: "Success",
                description: resp.data.message,
                variant: 'default',
                duration: 3000
            });
        } catch (error) {
            console.log(error);
            toast({
                title: "Error",
                description: (error as any).response.data.message,
                variant: 'destructive',
                duration: 3000
            })
        } finally {
            setIsOpen(false);
            setIsCreating(false);
        }
    }

    function isCreateCardValid() {
        if (data.account_id.length > 0 && data.type.length > 0 && !isCreating) {
            return true;
        }
        return false;
    }

  return (
    <Dialog open={isOpen} onOpenChange={(e) => setIsOpen(e)}>
            <DialogTrigger asChild onClick={() => setIsOpen(true)}>
                {children}
            </DialogTrigger>
            <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                    <DialogTitle>Create card</DialogTitle>
                    <DialogDescription>
                        Select a card type
                    </DialogDescription>
                </DialogHeader>

                <div className="grid gap-4 mt-4">
                    <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="name" className="text-right">
                            Account
                        </Label>
                        <Select value={data.account_id} onValueChange={(e) => setData((d) => ({ ...d, account_id: e }))}>
                            <SelectTrigger className="w-[180px]">
                                <SelectValue placeholder="Select account" />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectGroup>
                                    <SelectLabel>Accounts</SelectLabel>
                                    <SelectItem value={getAccountByType("usd", "savings").id.toString()}>
                                        <div className='flex items-center gap-2'>
                                            <img src="/assets/flags/usd.svg" className='w-4' alt="" />
                                            <div>Savings</div>
                                        </div>
                                    </SelectItem>
                                    <SelectItem value={getAccountByType("usd", "checking").id.toString()}>
                                        <div className='flex items-center gap-2'>
                                            <img src="/assets/flags/usd.svg" className='w-4' alt="" />
                                            <div>Checking</div>
                                        </div>
                                    </SelectItem>
                                    <SelectItem value={getAccountByType("eur", "savings").id.toString()}>
                                        <div className='flex items-center gap-2'>
                                            <img src="/assets/flags/eur.svg" className='w-4' alt="" />
                                            <div>Savings</div>
                                        </div>
                                    </SelectItem>
                                    <SelectItem value={getAccountByType("eur", "checking").id.toString()}>
                                        <div className='flex items-center gap-2'>
                                            <img src="/assets/flags/eur.svg" className='w-4' alt="" />
                                            <div>Checking</div>
                                        </div>
                                    </SelectItem>
                                </SelectGroup>
                            </SelectContent>
                        </Select>
                    </div>
                </div>

                <div className="grid gap-4">
                    <div className="grid grid-cols-4 items-center gap-4 mb-4">
                        <Label htmlFor="name" className="text-right">
                            Card Type
                        </Label>
                        <Select value={data.type} onValueChange={(e) => setData((d) => ({ ...d, type: e }))}>
                            <SelectTrigger className="w-[180px]">
                                <SelectValue placeholder="Select card" />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectGroup>
                                    <SelectLabel>Accounts</SelectLabel>
                                    <SelectItem value={"mastercard"}>
                                        <div className='flex items-center gap-2'>
                                            <img src="/assets/mastercard.png" className='w-4' alt="" />
                                            <div>Mastercard</div>
                                        </div>
                                    </SelectItem>
                                    <SelectItem value={"visa"}>
                                        <div className='flex items-center gap-2'>
                                            <img src="/assets/visa.png" className='w-4' alt="" />
                                            <div>Visa</div>
                                        </div>
                                    </SelectItem>
                                </SelectGroup>
                            </SelectContent>
                        </Select>
                    </div>
                </div>
                <DialogFooter>
                    <Button type="submit" disabled={!isCreateCardValid()} onClick={() => handleCreate()}>
                        {
                            !isCreating ?
                                <span>Create</span> :
                                <CgSpinner className='animate-spin' />
                        }
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
  )
}

export default CreateCard