import React, { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom';
import UserMenu from './UserMenu';
import { Toaster } from '@/_components/ui/toaster';
import Loader from './Loader';
import { useApp } from '@/contexts/appContext';

declare global {
    interface Window {
        google?: any;
        googleTranslateElementInit?: () => void;
    }
}

function AppLayout() {

    const { user, isAuthenticated, isAuthenticating, token } = useApp();

    const { pathname } = useLocation();

    function authMiddleware() {
        if (token === null) {
            window.location.href = "/auth/signin"
        }

        if (!user && !isAuthenticating) {
            window.location.href = "/auth/signin"
        }

        if (user && user.twofa === 1 && user.otp !== null) {
            window.location.href = "/auth/signin/2fa/otp";
        }
    }

    useEffect(() => {
        authMiddleware()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, token, isAuthenticating, user])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    // const googleTranslateElementInit = () => {
    //     if (window.google) {
    //         new window.google.translate.TranslateElement(
    //             {
    //                 pageLanguage: "en",
    //                 autoDisplay: false
    //             },
    //             "google_translate_element"
    //         );
    //     }
    // };

    // useEffect(() => {
    //     const addScript = document.createElement("script");
    //     addScript.setAttribute(
    //         "src",
    //         "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    //     );
    //     document.body.appendChild(addScript);

    //     window.googleTranslateElementInit = googleTranslateElementInit;

    //     return () => {
    //         // Clean up the script when the component unmounts
    //         document.body.removeChild(addScript);
    //     };
    // }, []);

    return (
        <>
            {!isAuthenticated || isAuthenticating || (user && user.twofa === 1 && user.otp !== null) ? <Loader /> :
                <div className='w-full min-h-[100vh] bg-background'>
                    <UserMenu />
                    <div className='flex justify-center'>
                        <div className='w-full md:w-[80%] px-[20px] md:px-0'>
                            <Outlet />
                        </div>
                    </div>

                    <div className='py-10'></div>
                </div>
            }
            <Toaster />
        </>
    )
}

export default AppLayout