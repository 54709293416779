import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom'
import AppLayout from './components/AppLayout';
import Home from './pages/user/Home';
import Login from './pages/auth/Login';
import AuthLayout from './components/AuthLayout';
import Otp from './pages/auth/Otp';
import Accounts from './pages/user/Accounts';
import Payments from './pages/user/Payments';
import Cards from './pages/user/Cards';
import PaymentsGlobal from './pages/user/PaymentsGlobal';
import Investments from './pages/user/Investments';
import Profile from './pages/user/Profile';
import Savings from './pages/user/Savings';
import Loans from './pages/user/Loans';
import Preference from './pages/user/Preference';
import PaymentsInternal from './pages/user/PaymentsInternal';
import PaymentsBill from './pages/user/PaymentsBill';
import FundAccount from './pages/user/FundAccount';
import FundAccountCheque from './pages/user/FundAccountCheque';
import FundAccountCrypto from './pages/user/FundAccountCrypto';

function App() {
  return (
    <Routes>
      <Route path='' element={<Navigate to={'/d/overview'} />} ></Route>
      <Route path='auth' element={<AuthLayout />}>
        <Route path='signin' element={<Login />} />
        <Route path='signin/2fa/otp' element={<Otp />} />
      </Route>
      
      <Route path='d' element={<AppLayout />}>
        <Route path='overview' element={<Home />} />
        <Route path='accounts' element={<Accounts />} />
        <Route path='accounts/:id/fund' element={<FundAccount />} />
        <Route path='accounts/:id/fund/cheque' element={<FundAccountCheque />} />
        <Route path='accounts/:id/fund/crypto' element={<FundAccountCrypto />} />
        <Route path='payments' element={<Payments />} />
        <Route path='payments/transfer/global' element={<PaymentsGlobal />} />
        <Route path='payments/transfer/internal' element={<PaymentsInternal />} />
        <Route path='payments/bill' element={<PaymentsBill />} />
        <Route path='investments' element={<Investments />} />
        <Route path='cards' element={<Cards />} />
        <Route path='profile' element={<Profile />} />
        <Route path='savings' element={<Savings />} />
        <Route path='loans' element={<Loans />} />
        <Route path='preference' element={<Preference />} />
      </Route>
    </Routes>
  );
}

export default App;
