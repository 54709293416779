
import React, { useEffect, useState } from 'react'

import { Button } from '@/_components/ui/button'

import {
    Card,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from "@/_components/ui/card"
import { ISavings } from '@/types/global.types'
import http from '@/lib/http'
import { useNavigate } from 'react-router-dom'
import { CgSpinner } from 'react-icons/cg'
import SaveInitiate from '@/components/SaveInitiate'
import { formatDate, numberFormat, upperCaseFirst } from '@/utils/helpers'
import { toast } from '@/_components/ui/use-toast'

function Savings() {

    const [savings, setSavings] = useState<ISavings[]>([])
    const [isFetching, setIsFetching] = useState(true)

    const navigate = useNavigate();

    async function getSavings() {
        try {
            const resp = await http().get('/user/savings');
            setSavings(resp.data.data);
        } catch (error) {
            console.log(error)
        } finally {
            setIsFetching(false);
        }
    }

    async function handleWithdrawSavings(id: number) {
        if (window.confirm("Ae you sure")) {
            try {
                const resp = await http().post('/user/savings/withdraw', { savings_id: `${id}` });
                toast({
                    title: "Success",
                    description: resp.data.message,
                    variant: 'default',
                    duration: 3000
                });
                setTimeout(() => {
                    window.location.href = "/d/accounts"
                }, 1000)
            } catch (error) {
                console.log(error)
                toast({
                    title: "Error",
                    description: (error as any).response.data.message,
                    variant: 'destructive',
                    duration: 3000
                })
            }
        }
    }

    useEffect(() => {
        getSavings();
    }, []);

    return (
        <div>

            <div className='mb-[30px]'>
                <div className='text-2xl font-pbold'>Savings</div>
                <div className='font-plight text-sm'>Earn interest on savings</div>
            </div>

            {
                !isFetching ?
                    <>
                        {
                            savings.length > 0 ?
                                <div>
                                    <div className='flex justify-end items-center mb-6'>
                                        <SaveInitiate setSavings={setSavings}>
                                            <Button className='mb-5 bg-green-600 text-white'>Get started</Button>
                                        </SaveInitiate>
                                    </div>

                                    <div className='space-y-4'>
                                        {
                                            savings.map((s, i) => (
                                                <div key={i} className='px-8 py-5 rounded-xl border shadow-md'>
                                                    <div className='flex justify-between gap-3 items-center'>
                                                        <div className='mb-4 text-xs inline-block px-3 py-1 rounded-2xl font-pregular bg-green-500/40'>Savings</div>
                                                        <div>
                                                            <Button onClick={() => handleWithdrawSavings(s.id)} className='text-xs' variant={'link'} size={'sm'}>Withdraw</Button>
                                                        </div>
                                                    </div>
                                                    <div className='space-y-4'>
                                                        <div className='space-y-1'>
                                                            <div className='font-plight text-xs'>Title</div>
                                                            <div className='text-sm'>{s.name}</div>
                                                        </div>
                                                        <div className='flex items-center gap-3'>
                                                            <div className='space-y-1'>
                                                                <div className='font-plight text-xs'>Amount</div>
                                                                <div className='text-sm'>{numberFormat(s.amount)}</div>
                                                            </div>
                                                            <div className='space-y-1'>
                                                                <div className='font-plight text-xs'>Interest</div>
                                                                <div className='text-sm text-green-500'>{s.interest}</div>
                                                            </div>
                                                        </div>
                                                        <div className='space-y-1'>
                                                            <div className='font-plight text-xs'>Expires</div>
                                                            <div className='text-sm'>{formatDate(s.expires_at)}</div>
                                                        </div>
                                                        <div className='space-y-1'>
                                                            <div className='font-plight text-xs'>Account</div>
                                                            <div className='text-sm'>{s.account.name.toUpperCase()} {upperCaseFirst(s.account.type)}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div> :
                                <>
                                    <div className='grid grid-cols-1 md:grid-cols-2 mb-[100px]'>
                                        <div>
                                            <Card className="w-full md:w-[280px] mb-[50px] relative overflow-hidden bg-cover">
                                                <CardHeader>
                                                    <CardTitle className='font-pbold'>$0.00</CardTitle>
                                                    <CardDescription>Savings</CardDescription>
                                                </CardHeader>
                                                <CardFooter className='relative'>
                                                    {/* <div className="absolute inset-0 bg-gradient-to-b from-transparent to-green-200 opacity-50 rounded-full"></div> */}
                                                    <Button className='w-full relative bg-green-600' disabled>Withdraw</Button>
                                                </CardFooter>
                                            </Card>
                                            <div className='flex justify-center items-center'>
                                                <div>
                                                    <div className='font-psemibold text-lg'>Discover</div>
                                                    <div className='font-plight mb-5'>Discover the power of saving with Klein bank. We believe that every individual deserves a secure financial future. Our savings accounts are designed to help you reach your goals, whether it's buying a home, funding your child's education, or simply building an emergency fund.</div>

                                                    <SaveInitiate setSavings={setSavings}>
                                                        <Button className='mb-5 bg-green-600 text-white'>Get started</Button>
                                                    </SaveInitiate>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='flex justify-center'>
                                            <center>
                                                <div className='mb-6'>
                                                    <div className='font-pbold text-2xl mb-6'>Grow Your <span className='text-green-600'>Money</span>, <br /> Grow Your <span className='text-green-600'>Future</span>.</div>
                                                    <div className='text-3xl font-pextralight'>10% APY</div>
                                                </div>
                                                <img src="/assets/grow.png" alt="card" className='w-[300px]' />
                                                <div></div>
                                            </center>
                                        </div>
                                    </div>

                                    <div className='w-full'>
                                        <div className='w-full h-[200px] bg-no-repeat bg-cover bg-center rounded-lg' style={{ backgroundImage: "url('/assets/sap500.png')" }}>
                                            <div className='w-full h-[200px] bg-cyan-900/80 rounded-lg relative px-10 py-5'>
                                                <div className='grid grid-cols-1 md:grid-cols-2'>
                                                    <div className='text-white space-y-2'>
                                                        <div className='font-plight text-2xl'>Invest in S&P 500 stocks</div>
                                                        <div className='text-sm font-pregular'> S&P 500 is a stock market index tracking the stock performance of 500 of the largest companies listed on stock exchanges in the United States</div>
                                                        <Button onClick={() => navigate('/d/investments')} className='mb-5 bg-green-600 text-white'>Get started</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                        }
                    </> :
                    <div className='w-full h-[300px] md:h-[500px] flex justify-center items-center animate-spin'>
                        <div>
                            <CgSpinner className='w-10 h-10' />
                        </div>
                    </div>
            }
        </div>
    )
}

export default Savings