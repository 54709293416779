'use client'

import { Button } from "@/_components/ui/button"
import {
    Sheet,
    SheetClose,
    SheetContent,
    SheetDescription,
    SheetHeader,
    SheetTitle,
    SheetTrigger,
} from "@/_components/ui/sheet"
import { useApp } from "@/contexts/appContext"
import { useTheme } from "@/contexts/themeProvider"
import { upperCaseFirst } from "@/utils/helpers"
import { LogOut, Moon, Sun } from "lucide-react"
import { CgMenuMotion } from "react-icons/cg"
import { Link } from "react-router-dom"
import NotificationMenuButton from "./NotificationMenuButton"

export function MobileMenuButton() {

    const { resolvedTheme, setTheme } = useTheme();
    const { logout } = useApp();

    return (
        <Sheet>
            <SheetTrigger asChild>
                <Button size={'icon'} variant={'ghost'} className='mt-1' >
                    {/* <img className='w-full' src="/assets/menu-slant.png" alt="" /> */}
                    <CgMenuMotion className='w-7 h-7' />
                </Button>
            </SheetTrigger>
            <SheetContent className="overflow-auto">
                <SheetHeader className="mb-10">
                    <div className="flex justify-between items-center gap-5">
                        <SheetTitle className="text-left">{process.env.REACT_APP_NAME}</SheetTitle>
                        <div>
                            <NotificationMenuButton />
                        </div>
                    </div>
                    <SheetDescription className="text-left">
                        Easy intuitive online banking. Manage your account, save, request loan in few clicks.
                    </SheetDescription>
                </SheetHeader>
                <div className="space-y-4">
                    <div>
                        <SheetClose asChild>
                            <Link to={'/d/overview'}>
                                <div>Overview</div>
                            </Link>
                        </SheetClose>
                    </div>
                    <div>
                        <SheetClose asChild>
                            <Link to={'/d/accounts'}>
                                <div>Accounts</div>
                            </Link>
                        </SheetClose>
                    </div>
                    <div>
                        <SheetClose asChild>
                            <Link to={'/d/payments'}>
                                <div>Payments</div>
                            </Link>
                        </SheetClose>
                    </div>
                    <div>
                        <SheetClose asChild>
                            <Link to={'/d/cards'}>
                                <div>Cards</div>
                            </Link>
                        </SheetClose>
                    </div>
                    <div>
                        <SheetClose asChild>
                            <Link to={'/d/savings'}>
                                <div>Savings</div>
                            </Link>
                        </SheetClose>
                    </div>
                    <div>
                        <SheetClose asChild>
                            <Link to={'/d/loans'}>
                                <div>Loans</div>
                            </Link>
                        </SheetClose>
                    </div>
                    <div>
                        <SheetClose asChild>
                            <Link to={'/d/investments'}>
                                <div>Investments</div>
                            </Link>
                        </SheetClose>
                    </div>
                    <div>
                        <SheetClose asChild>
                            <Link to={'/d/profile'}>
                                <div>Profile</div>
                            </Link>
                        </SheetClose>
                    </div>
                    <div>
                        <SheetClose asChild>
                            <Link to={'/d/preference'}>
                                <div>Preference</div>
                            </Link>
                        </SheetClose>
                    </div>

                    {/* <div>
                        <div id="google_translate_element"></div>
                    </div> */}

                    <div className="py-1">
                        <hr className="border-foreground/40 dark:border-foreground/20" />
                    </div>

                    <div className="cursor-pointer flex gap-1 items-center" onClick={() => setTheme(resolvedTheme === 'dark' ? 'light' : 'dark')}>
                        <div className="relative mr-6">
                            <Sun
                                className='absolute top-[-8px] transition-transform duration-400 mr-2 h-4 w-4 scale-0 dark:scale-100' />
                            <Moon
                                className='absolute top-[-8px] transition-transform duration-400 mr-2 h-4 w-4 scale-100 dark:scale-0' />
                        </div>
                        <span>{upperCaseFirst(resolvedTheme === "light" ? "dark" : "light")} mode</span>
                    </div>

                    <div className="py-1">
                        <hr className="border-foreground/40 dark:border-foreground/20" />
                    </div>

                    <div className="cursor-pointer" onClick={() => logout()}>
                        <div className="flex items-center gap-1">
                            <LogOut className="mr-2 h-4 w-4" />
                            <span>Log out</span>
                        </div>
                    </div>

                    <div className="py-10"></div>
                </div>
            </SheetContent>
        </Sheet>
    )
}
