
import { Button } from "@/_components/ui/button"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/_components/ui/dropdown-menu"
import { PiBellSimpleLight } from "react-icons/pi";
import React, { useEffect, useState } from "react";
import { cn } from "@/lib/utils";
import http from "@/lib/http";
import { CgSpinner } from "react-icons/cg";

function NotificationMenuButton() {

  const [notifications, setNotifications] = useState<{
    header: string,
    message: string,
    seen: number
  }[]>([])
  const [isFetching, setIsFetching] = useState(true)

  async function getNotifications() {
    try {
      const resp = await http().get('/user/notifications');
      setNotifications(resp.data.data);
    } catch (error) {
      console.log(error)
    } finally {
      setIsFetching(false);
    }
  }

  useEffect(() => {
    getNotifications();
  }, []);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>

        <Button variant="ghost">
          <PiBellSimpleLight className="w-[25px] h-[25px]" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        <DropdownMenuLabel>Notification</DropdownMenuLabel>
        <DropdownMenuSeparator />
        {!isFetching ?
          <>
            {
              notifications.length > 0 ?
                <>
                  <ul>
                    {
                      notifications.map((n, i) => (
                        <div>
                          <ListItem key={i} href="#" title={n.header}>
                            {n.message}
                          </ListItem>
                        </div>
                      ))
                    }
                  </ul>
                </> :
                <div className="w-full h-[300px] flex justify-center items-center">
                  <div>
                    No notification
                  </div>
                </div>
            }
          </> :
          <div className="w-full h-[300px] flex justify-center items-center">
            <div>
              <CgSpinner className="animate-spin" />
            </div>
          </div>
        }
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

const ListItem = React.forwardRef<
  React.ElementRef<"a">,
  React.ComponentPropsWithoutRef<"a">
>(({ className, title, children, ...props }, ref) => {
  return (
    <li>
      <a
        ref={ref}
        className={cn(
          "block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground",
          className
        )}
        {...props}
      >
        <div className="text-sm font-medium leading-none">{title}</div>
        <p className="line-clamp-2 text-sm leading-snug text-muted-foreground">
          {children}
        </p>
      </a>
    </li>
  )
})
ListItem.displayName = "ListItem"

export default NotificationMenuButton;