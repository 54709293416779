import React, { useState } from 'react'

import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/_components/ui/dialog"
import { Label } from '@/_components/ui/label';
import { Input } from '@/_components/ui/input';
import { Button } from '@/_components/ui/button';
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
} from "@/_components/ui/select"
import { IoSwapHorizontalSharp } from "react-icons/io5";
import { useApp } from '@/contexts/appContext';
import { CgSpinner } from 'react-icons/cg';
import { toast } from '@/_components/ui/use-toast';
import http from '@/lib/http';

function SwapFunds({ from }: { from: number }) {

    const { user, setUser } = useApp();

    function getAccountByType(currency: "usd" | "eur", type: "checking" | "savings") {
        const accounts_ = user!.accounts;
        const accounts = accounts_.filter((a) => a.name === currency.toLowerCase());
        return accounts.filter((a) => a.type === type)[0];
    }

    const [data, setData] = useState({
        from: from.toString(),
        to: "",
        amount: ""
    })

    const [isSwapping, setIsSwapping] = useState(false);
    const [isOpen, setIsOpen] = useState(false)

    function isSwapButtonValid() {
        if (!isSwapping && data.from.length > 0 && data.to.length > 0 && data.amount.length > 0 && data.from !== data.to) {
            return true;
        }
        return false;
    }

    async function handleSwap() {
        setIsSwapping(true);
        try {
            const resp = await http().post('/user/swap/fund', data);
            setUser((u) => ({ ...u!, accounts: resp.data.data }));
            toast({
                title: "Success",
                description: resp.data.message,
                variant: 'default',
                duration: 3000
            });
        } catch (error) {
            console.log(error);
            toast({
                title: "Error",
                description: (error as any).response.data.message,
                variant: 'destructive',
                duration: 3000
            })
        } finally {
            setIsOpen(false)
            setIsSwapping(false);
        }
    }

    return (
        <Dialog open={isOpen} onOpenChange={(e) => setIsOpen(e)}>
            <DialogTrigger asChild>
                <div onClick={() => setIsOpen(true)} className='bg-accent border w-[55px] h-[55px] pt-1 rounded-full flex justify-center items-center cursor-pointer'>
                    <center>
                        <IoSwapHorizontalSharp className='w-4 h-4' />
                        <div className='text-xs'>Swap</div>
                    </center>
                </div>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                    <DialogTitle>Swap funds</DialogTitle>
                    <DialogDescription>
                        Swap or convert funds between accounts
                    </DialogDescription>
                </DialogHeader>
                <div className="grid gap-4 py-4">
                    <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="name" className="text-right">
                            From
                        </Label>
                        <Select value={data.from} onValueChange={(e) => setData((d) => ({ ...d, from: e }))}>
                            <SelectTrigger className="w-[180px]">
                                <SelectValue placeholder="Select account" />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectGroup>
                                    <SelectLabel>Accounts</SelectLabel>
                                    <SelectItem value={getAccountByType("usd", "savings").id.toString()}>
                                        <div className='flex items-center gap-2'>
                                            <img src="/assets/flags/usd.svg" className='w-4' alt="" />
                                            <div>Savings</div>
                                        </div>
                                    </SelectItem>
                                    <SelectItem value={getAccountByType("usd", "checking").id.toString()}>
                                        <div className='flex items-center gap-2'>
                                            <img src="/assets/flags/usd.svg" className='w-4' alt="" />
                                            <div>Checking</div>
                                        </div>
                                    </SelectItem>
                                    <SelectItem value={getAccountByType("eur", "savings").id.toString()}>
                                        <div className='flex items-center gap-2'>
                                            <img src="/assets/flags/eur.svg" className='w-4' alt="" />
                                            <div>Savings</div>
                                        </div>
                                    </SelectItem>
                                    <SelectItem value={getAccountByType("eur", "checking").id.toString()}>
                                        <div className='flex items-center gap-2'>
                                            <img src="/assets/flags/eur.svg" className='w-4' alt="" />
                                            <div>Checking</div>
                                        </div>
                                    </SelectItem>
                                </SelectGroup>
                            </SelectContent>
                        </Select>
                    </div>
                    <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="username" className="text-right">
                            To
                        </Label>
                        <Select value={data.to} onValueChange={(e) => setData((d) => ({ ...d, to: e }))}>
                            <SelectTrigger className="w-[180px]">
                                <SelectValue placeholder="Select account" />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectGroup>
                                    <SelectLabel>Accounts</SelectLabel>
                                    <SelectItem value={getAccountByType("usd", "savings").id.toString()}>
                                        <div className='flex items-center gap-2'>
                                            <img src="/assets/flags/usd.svg" className='w-4' alt="" />
                                            <div>Savings</div>
                                        </div>
                                    </SelectItem>
                                    <SelectItem value={getAccountByType("usd", "checking").id.toString()}>
                                        <div className='flex items-center gap-2'>
                                            <img src="/assets/flags/usd.svg" className='w-4' alt="" />
                                            <div>Checking</div>
                                        </div>
                                    </SelectItem>
                                    <SelectItem value={getAccountByType("eur", "savings").id.toString()}>
                                        <div className='flex items-center gap-2'>
                                            <img src="/assets/flags/eur.svg" className='w-4' alt="" />
                                            <div>Savings</div>
                                        </div>
                                    </SelectItem>
                                    <SelectItem value={getAccountByType("eur", "checking").id.toString()}>
                                        <div className='flex items-center gap-2'>
                                            <img src="/assets/flags/eur.svg" className='w-4' alt="" />
                                            <div>Checking</div>
                                        </div>
                                    </SelectItem>
                                </SelectGroup>
                            </SelectContent>
                        </Select>
                    </div>
                    <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="amount" className="text-right">
                            Amount
                        </Label>
                        <Input
                            type='number'
                            id="amount"
                            className="col-span-3"
                            value={data.amount}
                            onChange={(e) => setData((d) => ({ ...d, amount: e.target.value }))}
                        />
                    </div>
                </div>
                <DialogFooter>
                    <Button type="submit" disabled={!isSwapButtonValid()} onClick={() => handleSwap()}>
                        {
                            !isSwapping ?
                                <span>Swap</span> :
                                <CgSpinner className='animate-spin' />
                        }
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}

export default SwapFunds