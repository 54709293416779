
import React, { useState } from 'react'

import {
    Card,
    CardContent,
    CardDescription,
    CardTitle,
} from "@/_components/ui/card"

import { BsSend } from "react-icons/bs";
import { Label } from '@/_components/ui/label';
import { Input } from '@/_components/ui/input';
import { Button } from '@/_components/ui/button';

import {
    Drawer,
    DrawerClose,
    DrawerContent,
    DrawerDescription,
    DrawerFooter,
    DrawerHeader,
    DrawerTitle,
    DrawerTrigger,
} from "@/_components/ui/drawer"
import { useApp } from '@/contexts/appContext';
import { numberFormat } from '@/utils/helpers';
import { CgSpinner } from 'react-icons/cg';
import { toast } from '@/_components/ui/use-toast';
import http from '@/lib/http';

function PaymentsGlobal() {

    const { user } = useApp();

    const [isLoading, setIsLoading] = useState(false);
    const [accountTab, setAccountTab] = useState<"usd-savings" | "usd-checking" | "eur-savings" | "eur-checking">("usd-savings")

    const getAcountId = (tab: string) => {
        return getAccountByType(tab.split("-")[0] as any, tab.split("-")[1] as any).id.toString();
    }

    const [form, setForm] = useState({
        account_id: getAcountId(accountTab),
        bank_code: "",
        bank_name: "",
        account_number: "",
        amount: ""
    })

    function getAccountByType(currency: "usd" | "eur", type: "checking" | "savings") {
        const accounts_ = user!.accounts;
        const accounts = accounts_.filter((a) => a.name === currency.toLowerCase());
        return accounts.filter((a) => a.type === type)[0];
    }

    async function handleSend() {
        setIsLoading(true);
        try {
            const resp = await http().post('/user/payments/transfer/global', form);
            toast({
                title: "Success",
                description: resp.data.message,
                variant: 'default',
                duration: 3000
            });
            setTimeout(() => {
                window.location.href = '/d/accounts';
            }, 1000)
        } catch (error) {
            console.log(error);
            toast({
                title: "Error",
                description: (error as any).response.data.message,
                variant: 'destructive',
                duration: 3000
            })
            setIsLoading(false);
        }
    }

    function isPopupButtonValid() {
        if (form.account_number.length > 0 && form.amount.length > 0 && form.bank_code.length > 0 && form.bank_name.length > 0 && !isLoading) {
            return true;
        }
        return false;
    }

    return (
        <div>
            {/* <div className='mb-[30px]'>
                <div className='text-2xl font-pbold'>Send Global</div>
                <div className='font-plight text-sm'>Make a quick global transfer in few clicks </div>
            </div> */}

            <div className='flex justify-center md:p-5'>
                <Card className='w-full md:w-[450px] lg:w-[600px] cursor-pointer'>
                    <CardContent className=" pt-8">
                        <div className='flex items-center justify-between gap-5 mb-8'>
                            <div className='space-y-2'>
                                <CardTitle>Send Global</CardTitle>
                                <CardDescription>
                                    Send to bank accounts accross the world
                                </CardDescription>
                            </div>
                            <div>
                                <div className='bg-accent border w-[55px] h-[55px] pt-1 rounded-full flex justify-center items-center cursor-pointer'>
                                    <center>
                                        <BsSend className='w-4 h-4' />
                                    </center>
                                </div>
                            </div>
                        </div>

                        <div>

                            <div className='mb-6 grid grid-cols-2 md:grid-cols-4 gap-3 items-center'>

                                <Card className={`w-full border-2 ${accountTab === "usd-savings" && 'border-green-500'}`} onClick={() => { setAccountTab("usd-savings"); setForm((f) => ({ ...f, account_id: getAcountId("usd-savings") })) }}>
                                    <CardContent className="space-y-1 pt-8">
                                        <img src="/assets/flags/usd.svg" className='w-4' alt="" />
                                        <div className="font-psemibold text-sm">${numberFormat(getAccountByType("usd", "savings").available_balance)}</div>
                                        <div className='font-plight text-xs'>
                                            Savings
                                        </div>
                                    </CardContent>
                                </Card>

                                <Card className={`w-full border-2 ${accountTab === "usd-checking" && 'border-green-500'}`} onClick={() => { setAccountTab("usd-checking"); setForm((f) => ({ ...f, account_id: getAcountId("usd-checking") })) }}>
                                    <CardContent className="space-y-1 pt-8">
                                        <img src="/assets/flags/usd.svg" className='w-4' alt="" />
                                        <div className="font-psemibold text-sm">${numberFormat(getAccountByType("usd", "checking").available_balance)}</div>
                                        <div className='font-plight text-xs'>
                                            Checking
                                        </div>
                                    </CardContent>
                                </Card>


                                <Card className={`w-full border-2 ${accountTab === "eur-savings" && 'border-green-500'}`} onClick={() => { setAccountTab("eur-savings"); setForm((f) => ({ ...f, account_id: getAcountId("eur-savings") })) }}>
                                    <CardContent className="space-y-1 pt-8">
                                        <img src="/assets/flags/eur.svg" className='w-4' alt="" />
                                        <div className="font-psemibold text-sm">€{numberFormat(getAccountByType("eur", "savings").available_balance)} </div>
                                        <div className='font-plight text-xs'>
                                            Savings
                                        </div>
                                    </CardContent>
                                </Card>

                                <Card className={`w-full border-2 ${accountTab === "eur-checking" && 'border-green-500'}`} onClick={() => { setAccountTab("eur-checking"); setForm((f) => ({ ...f, account_id: getAcountId("eur-checking") })) }}>
                                    <CardContent className="space-y-1 pt-8">
                                        <img src="/assets/flags/eur.svg" className='w-4' alt="" />
                                        <div className="font-psemibold text-sm">€{numberFormat(getAccountByType("eur", "checking").available_balance)}</div>
                                        <div className='font-plight text-xs'>
                                            Checking
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>

                            <div className="grid w-full max-w-sm items-center gap-1.5 mb-6">
                                <Label htmlFor="bank_code">Bank code</Label>
                                <Input value={form.bank_code} onChange={(e) => setForm(f => ({ ...f, bank_code: e.target.value }))} className='border-neutral-400/60 dark:border-neutral-400/20' type="text" id="bank_code" placeholder="Enter bank code" />
                            </div>

                            <div className="grid w-full max-w-sm items-center gap-1.5 mb-6">
                                <Label htmlFor="bank_name">Bank name</Label>
                                <Input value={form.bank_name} onChange={(e) => setForm(f => ({ ...f, bank_name: e.target.value }))} className='border-neutral-400/60 dark:border-neutral-400/20' type="text" id="bank_name" placeholder="Enter bank name" />
                            </div>

                            <div className="grid w-full max-w-sm items-center gap-1.5 mb-6">
                                <Label htmlFor="account_number">Account number</Label>
                                <Input value={form.account_number} onChange={(e) => setForm(f => ({ ...f, account_number: e.target.value }))} className='border-neutral-400/60 dark:border-neutral-400/20' type="text" id="account_number" placeholder="Enter your account number" />
                            </div>

                            <div className="grid w-full max-w-sm items-center gap-1.5 mb-6">
                                <Label htmlFor="amount">Amount</Label>
                                <Input value={form.amount} onChange={(e) => setForm(f => ({ ...f, amount: e.target.value }))} className='border-neutral-400/60 dark:border-neutral-400/20' type="number" id="amount" placeholder="0.00" />
                            </div>

                            {/* <Button className='mb-5 bg-green-600 text-white' onClick={handleSend} disabled={isLoading}>
                                {
                                    isLoading ?
                                        <PiSpinnerBold className='w-6 h-6 animate-spin' /> :
                                        <span>Send</span>
                                }
                            </Button> */}

                            <Drawer>
                                <DrawerTrigger asChild disabled={!isPopupButtonValid()}>
                                    <Button className='mb-5 bg-green-600 text-white' variant="outline">Send</Button>
                                </DrawerTrigger>
                                <DrawerContent>
                                    <div className="mx-auto w-full max-w-sm">
                                        <DrawerHeader>
                                            <DrawerTitle>Confirm transaction</DrawerTitle>
                                            <DrawerDescription>verify transaction and submit.</DrawerDescription>
                                        </DrawerHeader>
                                        <div className="p-0 mb-6">
                                            <div className='px-5 py-3 rounded-lg bg-accent mb-3'>
                                                <div className='text-sm font-pregular mb-1'>From</div>
                                                <div className='text-sm flex items-center gap-2'>
                                                    <img src={`/assets/flags/${accountTab.split("-")[0]}.svg`} className='w-4' alt="" />
                                                    <div>{accountTab.split("-")[1]}</div>
                                                </div>
                                                <div className="font-psemibold text-sm">
                                                    {accountTab.split("-")[0] === "usd" ? "$" : "€"}
                                                    {numberFormat(getAccountByType(accountTab.split("-")[0] as any, accountTab.split("-")[1] as any).available_balance)}
                                                </div>
                                            </div>

                                            <div className='px-5 py-3 rounded-lg bg-accent'>
                                                <div className='text-sm font-pregular mb-1'>To</div>
                                                <div className='text-sm flex items-center gap-2'>
                                                    <div>{form.bank_name}</div>
                                                </div>
                                                <div className='text-sm flex items-center gap-2'>
                                                    <div>{form.account_number}</div>
                                                </div>
                                                <div className='font-psemibold text-sm flex items-center gap-2'>
                                                    <div>{accountTab.split("-")[0] === "usd" ? "$" : "€"}{form.amount}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <DrawerFooter>
                                            <Button onClick={handleSend} disabled={isLoading}>
                                                {
                                                    !isLoading ?
                                                        <div>Submit</div> :
                                                        <CgSpinner className='animate-spin' />
                                                }
                                            </Button>
                                            <DrawerClose asChild disabled={isLoading}>
                                                <Button variant="outline">Cancel</Button>
                                            </DrawerClose>
                                            <div className='py-[100px]'></div>
                                        </DrawerFooter>
                                    </div>
                                </DrawerContent>
                            </Drawer>

                        </div>
                    </CardContent>
                </Card>
            </div>

            <div className='py-8'></div>
        </div>
    )
}

export default PaymentsGlobal