import classNames from 'classnames';
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import DesktopSettingsMenu from './DesktopSettingsMenuButton';
import NotificationMenuButton from './NotificationMenuButton';
import { MobileMenuButton } from './MobileMenuButton';

function UserMenu() {

    const { pathname } = useLocation();

    function getPath(path?: number) {
        const split_path = pathname.split('/');
        return split_path[path ? path : split_path.length - 1];
    }

    return (
        <>
            <div className='md:hidden flex justify-between gap-10 mb-10 px-8 py-2 items-center'>

                <div className='pt-1.5 flex gap-10 items-center'>
                    <div>
                        <img className='inline-block dark:hidden w-[100px]' src='/logo-full.png' alt='logo' />
                        <img className='hidden dark:inline-block w-[100px]' src='/logo-full-dark.png' alt='logo' />
                    </div>
                </div>

                <div className='flex gap-2 items-center'>
                    <MobileMenuButton />
                </div>

            </div>

            <div className='hidden md:flex justify-between gap-10 mb-10 px-8 py-2 items-center'>

                <div className='pt-1.5 flex gap-10 items-center'>
                    <div>
                        <img className='inline-block dark:hidden w-[100px]' src='/logo-full.png' alt='logo' />
                        <img className='hidden dark:inline-block w-[100px]' src='/logo-full-dark.png' alt='logo' />
                    </div>

                    <div className='flex gap-3'>
                        <Link className={classNames('px-3 py-2 rounded-xl text-sm hover:bg-accent', {
                            'bg-slate-100 dark:bg-slate-100/75 text-green-950 dark:text-blue-950 font-psemibold': getPath(2) === "overview",
                            'font-pregular': getPath(2) !== "overview"
                        })} to={'/d/overview'}>Overview</Link>
                        <Link className={classNames('px-3 py-2 rounded-xl text-sm hover:bg-accent', {
                            'bg-slate-100 dark:bg-slate-100/75 text-green-950 dark:text-blue-950 font-psemibold': getPath(2) === "accounts",
                            'font-pregular': getPath(2) !== "accounts"
                        })} to={'/d/accounts'}>Accounts</Link>
                        <Link className={classNames('px-3 py-2 rounded-xl text-sm hover:bg-accent', {
                            'bg-slate-100 dark:bg-slate-100/75 text-green-950 dark:text-blue-950 font-psemibold': getPath(2) === "payments",
                            'font-pregular': getPath(2) !== "payments"
                        })} to={'/d/payments'}>Payments</Link>
                        <Link className={classNames('px-3 py-2 rounded-xl text-sm hover:bg-accent', {
                            'bg-slate-100 dark:bg-slate-100/75 text-green-950 dark:text-blue-950 font-psemibold': getPath(2) === "cards",
                            'font-pregular': getPath(2) !== "cards"
                        })} to={'/d/cards'}>Cards</Link>
                        <Link className={classNames('px-3 py-2 rounded-xl text-sm hover:bg-accent', {
                            'bg-slate-100 dark:bg-slate-100/75 text-green-950 dark:text-blue-950 font-psemibold': getPath(2) === "savings",
                            'font-pregular': getPath(2) !== "savings"
                        })} to={'/d/savings'}>Savings</Link>
                        <Link className={classNames('px-3 py-2 rounded-xl text-sm hover:bg-accent', {
                            'bg-slate-100 dark:bg-slate-100/75 text-green-950 dark:text-blue-950 font-psemibold': getPath(2) === "loans",
                            'font-pregular': getPath(2) !== "Loans"
                        })} to={'/d/loans'}>Loans</Link>
                        <Link className={classNames('px-3 py-2 rounded-xl text-sm hover:bg-accent', {
                            'bg-slate-100 dark:bg-slate-100/75 text-green-950 dark:text-blue-950 font-psemibold': getPath(2) === "investments",
                            'font-pregular': getPath(2) !== "investments"
                        })} to={'/d/investments'}>Investments</Link>
                    </div>
                </div>



                <div className='flex gap-6 items-center'>
                    <div className='flex items-center gap-2'>
                        <div>
                            <DesktopSettingsMenu />
                        </div>
                        <div>
                            <NotificationMenuButton />
                        </div>
                    </div>

                    <div className='flex gap-2 items-center'>
                        <div className='px-2 py-2 text-sm'>Andrew mclain</div>
                        <div className='w-[32px] h-[32px] bg-slate-100 rounded-full overflow-hidden p-1'>
                            <img className='' src='/assets/user3.png' alt='user' />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserMenu