
import { Button } from '@/_components/ui/button';
import { Input } from '@/_components/ui/input';
import { Label } from '@/_components/ui/label';
import { toast } from '@/_components/ui/use-toast';
import CustomFileInput from '@/components/CustomFileInput';
import http from '@/lib/http';
import React, { useState } from 'react'
import { CgSpinner } from 'react-icons/cg';
import { useNavigate, useParams } from 'react-router-dom';

function FundAccountCheque() {

    const { id } = useParams();
    const [isFetching, setIsFetching] = useState(false);
    const navigate = useNavigate();

    const [form, setForm] = useState<{
        account_id: string,
        front_image: File | null,
        back_image: File | null,
        cheque_number: string,
        amount: string
    }>({
        account_id: id!,
        front_image: null,
        back_image: null,
        cheque_number: "",
        amount: ""
    });

    async function handleDepositCheque() {
        setIsFetching(true);
        try {
            const formData = new FormData();
            formData.append('account_id', form.account_id);
            formData.append('front_image', form.front_image!);
            formData.append('back_image', form.back_image!);
            formData.append('cheque_number', form.cheque_number);
            formData.append('amount', form.amount);
            const resp = await http().post('/user/fund/cheque', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            toast({
                title: "Success",
                description: resp.data.message,
                variant: 'default',
                duration: 3000
            });
            navigate('/d/accounts');
        } catch (error) {
            console.log(error);
            toast({
                title: "Error",
                description: (error as any).response.data.message,
                variant: 'destructive',
                duration: 3000
            });
        }
    }

    function isSubmitButtonValid() {
        if (form.front_image && form.back_image && form.cheque_number.length > 0 && form.amount.length > 0) {
            return true;
        }
        return false;
    }

    return (
        <div>

            <div className='mb-[60px]'>
                <div className='text-2xl font-pbold'>Deposit Cheque</div>
                <div className='font-plight text-sm'>Fund account by depositing cheques</div>
            </div>

            <div className='space-y-5'>
                <div className="grid w-full max-w-sm items-center gap-1.5 mb-6">
                    <Label htmlFor="cheque_number">Cheque number</Label>
                    <Input value={form.cheque_number} onChange={(e) => setForm(f => ({ ...f, cheque_number: e.target.value }))} className='border-neutral-400/60 dark:border-neutral-400/20' type="text" id="cheque_number" placeholder="Enter cheque number" />
                </div>
            </div>

            <div className='space-y-5'>
                <div className="grid w-full max-w-sm items-center gap-1.5 mb-6">
                    <Label htmlFor="amount">Amount</Label>
                    <Input value={form.amount} onChange={(e) => setForm(f => ({ ...f, amount: e.target.value }))} className='border-neutral-400/60 dark:border-neutral-400/20' type="number" id="amount" placeholder="Enter amount" />
                </div>
            </div>

            <div className='space-y-5'>
                <div className="grid w-full max-w-sm items-center gap-1.5 mb-6">
                    <Label htmlFor="front_image">Front image</Label>
                    <CustomFileInput onChange={(e) => setForm(f => ({ ...f, front_image: e }))} />
                </div>
            </div>

            <div className='space-y-5'>
                <div className="grid w-full max-w-sm items-center gap-1.5 mb-6">
                    <Label htmlFor="back_image">Back image</Label>
                    <CustomFileInput onChange={(e) => setForm(f => ({ ...f, back_image: e }))} />
                </div>
            </div>

            <Button onClick={() => handleDepositCheque()} className='mt-5 bg-green-600 text-white w-[200px]' variant="outline" disabled={!isSubmitButtonValid()}>
                {!isFetching ?
                    <div>Submit</div> :
                    <CgSpinner className='animate-spin' />
                }
            </Button>
        </div>
    )
}

export default FundAccountCheque