
import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:8000/api";

export const getToken = () => {
    const token = localStorage.getItem('bkapp-tkn');
    return !token ? null : token
}

export default function http() {
    const axios_ = axios.create({
        baseURL: baseUrl,
        headers: {
            Authorization: `Bearer ${getToken()}`
        }
    });

    return axios_
}