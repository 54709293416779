
import React, { createContext, useContext, useEffect, useMemo, useState } from "react"
import { IAppContext, IUser } from "@/types/global.types";
import http from "@/lib/http";
import { BadRequestError, ValidationError } from "@/utils/errors";
import { AxiosError } from "axios";

export const AppContext = createContext<IAppContext | undefined>(undefined);

function AppProvider({ children }: { children: React.ReactNode }) {

  const [user, setUser] = useState<IUser | null>(null);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [authError, setAuthError] = useState<object | null>(null)
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  const token = useMemo(() => {
    const token_ = localStorage.getItem('bkapp-tkn');
    return !token_ ? null : token_ /*jwt.decode(token_)*/
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, user])

  useEffect(() => {
    setIsMounted(true);
  }, [])

  async function getUser() {
    setIsAuthenticating(true);
    try {
      const resp = await http().get('/user');
      setUser(resp.data.data)
      setIsAuthenticated(true);
      console.log(resp.data)
      setIsAuthenticating(false);
    } catch (error) {
      console.log("error", error)
      if ((error as any).response.status === 401) {
        localStorage.removeItem('bkapp-tkn');
        setIsAuthenticated(false)
      }
      setIsAuthenticating(false)
    }
  }

  useEffect(() => {
    getUser();
  }, []);

  async function authenticate(data: any) {
    if (isAuthenticated) throw new BadRequestError("Already authenticated")
    setIsAuthenticating(true)
    setAuthError(null)
    try {
      const resp = await http().post('/auth/login', data);
      setToken(resp.data.token)
      console.log("token",resp.data.token)
      await getUser();
      return ({
        authentication: resp.data.authentication,
        errors: null,
      });
    } catch (error) {
      console.log('Auth Errors', (error as any).response.data)
      setIsAuthenticating(false)
      localStorage.removeItem('bkapp-tkn');
      setAuthError((error as any).response.data)
      setUser(null)
      const err = (error as AxiosError);
      if (err.response?.status === 422) {
        throw new ValidationError((err.response?.data as any).message, (err.response?.data as any).errors)
      }else {
        throw new BadRequestError((err.response?.data as any).message)
      }
    }
  }

  function setToken(token: string) {
    // let token_ = jwt.sign(`${token}`, 'myappsecret');
    localStorage.setItem('bkapp-tkn', token);
  }

  async function logout() {
    setIsAuthenticating(true);
    try {
      await http().post("/auth/logout");
      localStorage.removeItem('bkapp-tkn');
      setIsAuthenticated(false);
      setUser(null);
    } catch (error) {
      console.log(error)
      setIsAuthenticating(false);
    }
  }

  return (
    <AppContext.Provider value={{
      isMounted,
      user,
      setUser,
      isAuthenticating,
      setIsAuthenticating,
      isAuthenticated,
      setIsAuthenticated,
      authError,
      setAuthError,
      token,
      setToken,
      authenticate,
      logout
    }}>
      {children}
    </AppContext.Provider>
  )
}

export function useApp() {
  return useContext<IAppContext | undefined>(AppContext)!;
}

export default AppProvider