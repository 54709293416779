import React, { useState } from 'react'

import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/_components/ui/dialog"
import { Label } from '@/_components/ui/label';
import { Input } from '@/_components/ui/input';
import { Button } from '@/_components/ui/button';
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
} from "@/_components/ui/select"
import { useApp } from '@/contexts/appContext';
import { CgSpinner } from 'react-icons/cg';
import { toast } from '@/_components/ui/use-toast';
import http from '@/lib/http';

function PrintStatement({ account }: { account: number }) {

    const { user } = useApp();

    function getAccountByType(currency: "usd" | "eur", type: "checking" | "savings") {
        const accounts_ = user!.accounts;
        const accounts = accounts_.filter((a) => a.name === currency.toLowerCase());
        return accounts.filter((a) => a.type === type)[0];
    }

    const [data, setData] = useState({
        account: account.toString(),
        from: "",
        to: ""
    })

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isOpen, setIsOpen] = useState(false)

    function isPrintStatementButtonValid() {
        if (!isSubmitting && data.from.length > 0 && data.to.length > 0 && data.account.length > 0 && data.from !== data.to) {
            return true;
        }
        return false;
    }

    async function handlePrint() {
        setIsSubmitting(true);
        try {
            const resp = await http().post('/user/transactions/statement', data);
            const transactions = resp.data.data;

            toast({
                title: "Success",
                description: "Printing statement",
                variant: 'default',
                duration: 3000
            });

            // Create printable content
            const printWindow = window.open('', '', 'width=800,height=600');
            printWindow?.document.write(`
                <html>
                <head>
                    <title>Transaction Statement</title>
                    <style>
                        body { font-family: Arial, sans-serif; margin: 20px; }
                        table { width: 100%; border-collapse: collapse; margin-top: 20px; }
                        th, td { border: 1px solid #ddd; padding: 8px; text-align: left; }
                        th { background-color: #f2f2f2; }
                        img { width: 150px; margin-bottom: 20px; }
                    </style>
                </head>
                <body>
                    <img src="http://127.0.0.1:8000/logo-full.png" alt="Logo" />
                    <h1>Royal RBC</h1>
                    <h2>Transaction Statement</h2>
                    <table>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Narration</th>
                                <th>Amount</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            ${transactions.map((transaction: any) => `
                                <tr>
                                    <td>${new Date(transaction.created_at).toLocaleDateString()}</td>
                                    <td>${transaction.narration || 'N/A'}</td>
                                    <td>$${transaction.amount}</td>
                                    <td>${transaction.status === 1 ? 'Successful' : 'Failed'}</td>
                                </tr>
                            `).join('')}
                        </tbody>
                    </table>
                </body>
                </html>
            `);

            // Print the document
            printWindow?.document.close();
            printWindow?.focus();
            printWindow?.print();
            printWindow?.close();

            setIsSubmitting(false);
        } catch (error) {
            console.log(error);
            toast({
                title: "Error",
                description: (error as any).response.data.message,
                variant: 'destructive',
                duration: 3000
            });
            setIsSubmitting(false);
        }
    }


    return (
        <Dialog open={isOpen} onOpenChange={(e) => setIsOpen(e)}>
            <DialogTrigger asChild>
                <Button variant={'ghost'} size={'sm'} onClick={() => setIsOpen(true)}>Print statement</Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                    <DialogTitle>Print statement</DialogTitle>
                    <DialogDescription>
                        Print account statement
                    </DialogDescription>
                </DialogHeader>
                <div className="grid gap-4 py-4">
                    <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="name" className="text-right">
                            Account
                        </Label>
                        <Select value={data.account} onValueChange={(e) => setData((d) => ({ ...d, account: e }))}>
                            <SelectTrigger className="w-[180px]">
                                <SelectValue placeholder="Select account" />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectGroup>
                                    <SelectLabel>Accounts</SelectLabel>
                                    <SelectItem value={getAccountByType("usd", "savings").id.toString()}>
                                        <div className='flex items-center gap-2'>
                                            <img src="/assets/flags/usd.svg" className='w-4' alt="" />
                                            <div>Savings</div>
                                        </div>
                                    </SelectItem>
                                    <SelectItem value={getAccountByType("usd", "checking").id.toString()}>
                                        <div className='flex items-center gap-2'>
                                            <img src="/assets/flags/usd.svg" className='w-4' alt="" />
                                            <div>Checking</div>
                                        </div>
                                    </SelectItem>
                                    <SelectItem value={getAccountByType("eur", "savings").id.toString()}>
                                        <div className='flex items-center gap-2'>
                                            <img src="/assets/flags/eur.svg" className='w-4' alt="" />
                                            <div>Savings</div>
                                        </div>
                                    </SelectItem>
                                    <SelectItem value={getAccountByType("eur", "checking").id.toString()}>
                                        <div className='flex items-center gap-2'>
                                            <img src="/assets/flags/eur.svg" className='w-4' alt="" />
                                            <div>Checking</div>
                                        </div>
                                    </SelectItem>
                                </SelectGroup>
                            </SelectContent>
                        </Select>
                    </div>

                    <div className="grid gap-4">
                        <div className="grid grid-cols-4 items-center gap-4">
                            <Label htmlFor="name" className="text-right">
                                From
                            </Label>
                            <Input
                                type='datetime-local'
                                id="from"
                                className="col-span-3"
                                onChange={(e) => setData((d) => ({ ...d, from: e.target.value }))}
                            />
                        </div>
                    </div>
                    <div className="grid gap-4">
                        <div className="grid grid-cols-4 items-center gap-4 mb-4">
                            <Label htmlFor="name" className="text-right">
                                To
                            </Label>
                            <Input
                                type='datetime-local'
                                id="to"
                                className="col-span-3"
                                onChange={(e) => setData((d) => ({ ...d, to: e.target.value }))}
                            />
                        </div>
                    </div>
                </div>
                <DialogFooter>
                    <Button type="submit" disabled={!isPrintStatementButtonValid()} onClick={() => handlePrint()}>
                        {
                            !isSubmitting ?
                                <span>Print</span> :
                                <CgSpinner className='animate-spin' />
                        }
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}

export default PrintStatement