
import React, { useEffect, useState } from 'react'
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from "@/_components/ui/card"
import { Button } from '@/_components/ui/button';
import { Input } from '@/_components/ui/input';
import { TbTransferIn, TbTransferOut } from "react-icons/tb";
import OverviewBalanceChart from '@/components/OverviewBalanceChart';
import { Link, useNavigate } from 'react-router-dom';
import AccountCarousel from '@/components/AccountCarousel';
import { IInvestment } from '@/types/global.types';
import http from '@/lib/http';
import { Skeleton } from '@/_components/ui/skeleton';
import { upperCaseFirst } from '@/utils/helpers';

function Home() {
    const [investments, setInvestments] = useState<IInvestment[]>([]);
    const [isFetchingData, setIsFetchingData] = useState(true);
    const navigate = useNavigate();

    async function getDatas() {
        try {
            const resp = await Promise.all([
                http().get('/user/investments'),
            ]);
            setInvestments(resp[0].data.data);
        } catch (error) {
            console.log(error);
        } finally {
            setIsFetchingData(false)
        }
    }

    useEffect(() => {
        getDatas();
    }, [])

    function getPNLPercent(amount: number, interest: number) {
        return ((interest * 100) / amount).toFixed(2);
    }

    return (
        <div className="grid grid-cols-1 md:flex gap-5 md:gap-10">

            <div className='w-full md:w-[300px]'>
                <div className='md:hidden block'>
                    <AccountCarousel />
                    <div className='py-1'></div>
                    <AccountCarousel currency='EUR' />
                </div>

                <div className='hidden md:block'>
                    <AccountCarousel />
                    <AccountCarousel currency='EUR' />

                    <Card className="w-full md:w-[280px] relative overflow-hidden bg-cover">
                        <CardHeader>
                            {/* <CardTitle className='font-pbold'>$0.00</CardTitle> */}
                            <CardDescription>Loans</CardDescription>
                        </CardHeader>
                        <CardContent>
                            <div className='relative'>
                                <div className='text-xs'>Get the financing you need with our competitive loan rates.
                                    Apply for a loan online or visit one of our branches.
                                    We offer a variety of loan products to suit your needs.
                                </div>
                            </div>
                        </CardContent>
                        <CardFooter className='relative'>
                            <div className="absolute inset-0 bg-gradient-to-b from-transparent to-green-200 opacity-50 rounded-full"></div>
                            <Button onClick={() => navigate('/d/loans')} className='w-full relative bg-green-600'>Learn more</Button>
                        </CardFooter>
                    </Card>
                </div>

            </div>

            <div className='flex-1'>

                <Card className="w-full relative overflow-hidden mb-5 bg-cover">
                    <CardHeader>
                        <CardTitle className='font-pbold flex gap-2 items-center'>
                            <div className='text-2xl'>Welcome</div>
                            <div className='text-2xl text-green-700'>Andrew</div>
                        </CardTitle>
                        <CardDescription>Access & manage your account and transactions efficiently</CardDescription>
                        <div className='pt-1'>
                            <Input placeholder='Quick Search' className='' />
                        </div>
                    </CardHeader>
                    <CardContent>
                        <div className=''>
                            <OverviewBalanceChart />
                        </div>
                    </CardContent>
                </Card>

                <Card className="w-full relative overflow-hidden mb-5 bg-cover">
                    <CardContent className='pt-[30px]'>
                        <div className='w-full'>

                            <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                                <div>
                                    <div className='font-pbold mb-2'>
                                        Quick access
                                    </div>

                                    <div className='grid grid-cols-2 md:grid-cols-2 gap-2'>

                                        <Card className="w-full relative overflow-hidden bg-cover cursor-pointer" onClick={() => navigate('/d/payments/transfer/global')} >
                                            <CardHeader className=''>
                                                <CardTitle className='font-pregular text-md'>Transfer</CardTitle>
                                            </CardHeader>
                                            <CardContent>
                                                <div className='relative flex items-start gap-1'>
                                                    <div>
                                                        <TbTransferOut className='w-5 h-5' />
                                                    </div>
                                                    <div className='text-xs'>Transfer funds in few clicks</div>
                                                </div>
                                            </CardContent>
                                        </Card>

                                        <Card className="w-full relative overflow-hidden bg-cover cursor-pointer" onClick={() => navigate('/d/accounts')} >
                                            <CardHeader className=''>
                                                <CardTitle className='font-pregular text-md'>Receive</CardTitle>
                                            </CardHeader>
                                            <CardContent>
                                                <div className='relative flex items-start gap-1'>
                                                    <div>
                                                        <TbTransferIn className='w-5 h-5' />
                                                    </div>
                                                    <div className='text-xs'>View account information</div>
                                                </div>
                                            </CardContent>
                                        </Card>

                                        <Card className="w-full relative overflow-hidden bg-cover cursor-pointer" onClick={() => navigate('/d/payments/bill')} >
                                            <CardHeader className=''>
                                                <CardTitle className='font-pregular text-md'>Pay bills</CardTitle>
                                            </CardHeader>
                                            <CardContent>
                                                <div className='relative flex items-start gap-1'>
                                                    <div>
                                                        <TbTransferOut className='w-5 h-5' />
                                                    </div>
                                                    <div className='text-xs'>Utility bills, cable & more</div>
                                                </div>
                                            </CardContent>
                                        </Card>

                                        <Card className="w-full relative overflow-hidden bg-cover cursor-pointer" onClick={() => navigate('/d/accounts')} >
                                            <CardHeader className=''>
                                                <CardTitle className='font-pregular text-md'>Convert</CardTitle>
                                            </CardHeader>
                                            <CardContent>
                                                <div className='relative flex items-start gap-1'>
                                                    <div>
                                                        <TbTransferIn className='w-5 h-5' />
                                                    </div>
                                                    <div className='text-xs'>Quickly Swap funds</div>
                                                </div>
                                            </CardContent>
                                        </Card>
                                    </div>
                                </div>

                                {!isFetchingData ? <div className='md:pl-[20px]'>
                                    <div className='flex justify-between items-center gap-4 mb-4'>
                                        <div className='font-pbold pl-1'>
                                            Investments
                                        </div>
                                        <div>
                                            <Link to={'/d/investments'} className='font-pregular text-sm'>See all</Link>
                                        </div>
                                    </div>

                                    {
                                        investments.length > 0 ?
                                            <div>
                                                {
                                                    investments.slice(0, 5).map((e, i) => (
                                                        <div key={i} className='w-full rounded-xl bg-neutral-50 dark:bg-slate-900/70 px-2 py-2 mt-3'>
                                                            <div className='flex gap-3 justify-between items-center'>
                                                                <div className='flex gap-3'>
                                                                    <div>
                                                                        <div className='w-[20px] h-[20px] rounded-full overflow-hidden'>
                                                                            <img className='w-full' src={e.category.logo} alt="" />
                                                                        </div>
                                                                    </div>
                                                                    <div>{upperCaseFirst(e.category.name)}</div>
                                                                </div>

                                                                <div className={`text-[13px] ${parseFloat(getPNLPercent(e.amount, e.interest)) >= 0 ? 'text-green-600' : 'text-red-600'} font-pmedium`}>{getPNLPercent(e.amount, e.interest)}%</div>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div> :
                                            <div className='w-full h-[250px] bg-no-repeat bg-center rounded-lg' style={{ backgroundImage: "url('/assets/sap500.jpg')" }}>
                                                <div className='w-full h-[250px] bg-cyan-900/80 rounded-lg relative px-10 py-5'>
                                                    <div className='text-white space-y-2'>
                                                        <div className='font-pregular text-xl'>Invest in S&P 500 stocks</div>
                                                        <div className='text-xs font-pregular'> S&P 500 is a stock market index tracking the stock performance of 500 of the largest companies listed on stock exchanges in the United States</div>
                                                        <Button onClick={() => navigate('/d/investments')} className='mb-5 bg-green-600 text-white'>Get started</Button>
                                                    </div>
                                                </div>
                                            </div>
                                    }

                                </div> :
                                    <div className='md:pl-[20px]'>
                                        <div className='flex justify-between items-center gap-4 mb-4'>
                                            <div className='font-pbold pl-1'>
                                                Investments
                                            </div>
                                            <div>
                                                <Link to={'/d/investments'} className='font-pregular text-sm'>See all</Link>
                                            </div>
                                        </div>

                                        {
                                            Array.from(Array(5)).map((_, i) => (
                                                <div key={i} className='w-full rounded-xl bg-neutral-50 dark:bg-slate-900/70 px-2 py-2 mt-3'>
                                                    <Skeleton className='h-[25px]' />
                                                </div>
                                            ))
                                        }

                                    </div>
                                }
                            </div>
                        </div>

                    </CardContent>
                </Card>

                <Card className="w-full relative mb-5 bg-cover" style={{ backgroundImage: "url('/assets/liquid.jpg')" }}>
                    <CardHeader>
                        <CardTitle className='font-pbold text-3xl text-white'>Protect your money</CardTitle>
                    </CardHeader>
                    <CardContent>
                        <div className='relative'>
                            <div className='grid grid-cols-1 md:grid-cols-2'>
                                <div className='relative text-white px-5 py-5 rounded-lg font-pregular text-sm bg-cyan-900/70'>
                                    setup two factor authentication to protect your account from authorized access.
                                </div>
                                <div></div>
                            </div>
                            <img className='absolute -right-[35px] md:-right-[10px] top-[50px] md:-top-[60px] w-[120px] md:w-[160px]' src='/assets/secure.png' alt='lock' />
                        </div>
                    </CardContent>
                </Card>

                <div className='py-[50px]'></div>
            </div>
        </div>
    )
}

export default Home