
import { Button } from '@/_components/ui/button'
import { Card, CardContent } from '@/_components/ui/card'
import CreateCard from '@/components/CreateCard'
import http from '@/lib/http'
import { ICard } from '@/types/global.types'
import { upperCaseFirst } from '@/utils/helpers'
import React, { useEffect, useState } from 'react'
import { CgSpinner } from 'react-icons/cg'

function Cards() {

    const [cards, setCards] = useState<ICard[]>([])
    const [isFetching, setIsFetching] = useState(true)

    async function getCards() {
        try {
            const resp = await http().get('/user/cards');
            setCards(resp.data.data);
        } catch (error) {
            console.log(error)
        } finally {
            setIsFetching(false);
        }
    }

    useEffect(() => {
        getCards();
    }, [])

    return (
        <div>

            <div className='mb-[30px]'>
                <div className='text-2xl font-pbold'>Manage Cards</div>
                <div className='font-plight text-sm'>Create physical or virtual cards</div>
            </div>

            {
                !isFetching ?
                    <>
                        {
                            cards.length > 0 ?
                                <div>
                                    <div className='flex justify-end items-center mb-6'>
                                        <CreateCard setCards={setCards}>
                                            <Button className='mb-5 bg-green-600 text-white'>Create card</Button>
                                        </CreateCard>
                                    </div>

                                    <div className='space-y-4'>
                                        {
                                            cards.map((c, i) => (
                                                <Card key={i}>
                                                    <CardContent className="pt-5">
                                                        <div className='rounded-xl flex gap-5 items-center'>
                                                            <div>
                                                                <img src={`/assets/${c.type}.png`} alt="card" className='w-[30px]' />
                                                            </div>
                                                            <div className='space-y-4'>
                                                                <div className='space-y-1'>
                                                                    <div className='font-plight text-xs'>Card number</div>
                                                                    <div className='font-pmedium'>{c.pan}</div>
                                                                </div>
                                                                <div className='flex items-center gap-3'>
                                                                    <div className='space-y-1'>
                                                                        <div className='font-plight text-xs'>Expiry date</div>
                                                                        <div className='font-pmedium'>{c.expiry_date}</div>
                                                                    </div>
                                                                    <div className='space-y-1'>
                                                                        <div className='font-plight text-xs'>CVV</div>
                                                                        <div className='font-pmedium'>{c.cvv}</div>
                                                                    </div>
                                                                </div>
                                                                <div className='space-y-1'>
                                                                    <div className='font-plight text-xs'>Account</div>
                                                                    <div className='text-sm'>{c.account.name.toUpperCase()} {upperCaseFirst(c.account.type)}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </CardContent>
                                                </Card>
                                            ))
                                        }
                                    </div>
                                </div>
                                :
                                <div className='grid grid-cols-1 md:grid-cols-2'>
                                    <div className='flex justify-center items-center'>
                                        <div>
                                            <div className='font-psemibold text-lg'>No card found</div>
                                            <div className='font-plight mb-5'>Create a card in few steps. Have your card delivered in less than 48 hours</div>

                                            <CreateCard setCards={setCards}>
                                                <Button className='mb-5 bg-green-600 text-white'>Create card</Button>
                                            </CreateCard>
                                        </div>
                                    </div>
                                    <div>
                                        <img src="/assets/hand-card.png" alt="card" className='w-[70%]' />
                                    </div>
                                </div>
                        }
                    </> :
                    <div className='w-full h-[300px] md:h-[500px] flex justify-center items-center animate-spin'>
                        <div>
                            <CgSpinner className='w-10 h-10' />
                        </div>
                    </div>
            }
        </div>
    )
}

export default Cards