import { Button } from '@/_components/ui/button'
import { Input } from '@/_components/ui/input'
import { Label } from '@/_components/ui/label'
import { toast } from '@/_components/ui/use-toast'
import { useApp } from '@/contexts/appContext'
import { BadRequestError, ValidationError } from '@/utils/errors'
import React, { useState } from 'react'
import { PiSpinnerBold } from 'react-icons/pi'
import { useNavigate } from 'react-router-dom'

function Login() {

    const navigate = useNavigate();
    const { authenticate, isAuthenticating } = useApp();
    const [form, setForm] = useState({
        email: "",
        password: ""
    })
    const [emailError, setEmailError] = useState<string[]>([])
    const [passwordError, setPasswordError] = useState<string[]>([])


    async function handleLogin() {
        if (!form.email || !form.password) {
            toast({
                title: "Invalid input",
                description: "Atleast one field was left undefined",
                variant: 'destructive',
                duration: 2000
            })
            return;
        }
        try {
            const resp = await authenticate(form);
            console.log("resp", resp);
            if (resp.authentication === "OTP") {
                navigate('/auth/signin/2fa/otp');
            } else {
                navigate('/d/overview');
            }
        } catch (error) {
            const err = error as BadRequestError | ValidationError;
            console.log("Error:", err)

            if (error instanceof ValidationError) {
                ((error as ValidationError).data as any).password && setPasswordError((err) => [...((error as ValidationError).data as any).password]);
                ((error as ValidationError).data as any).email && setEmailError((err) => [...((error as ValidationError).data as any).email]);
            }

            if (error instanceof BadRequestError) {
                toast({
                    title: "Error",
                    description: (error as BadRequestError).message,
                    variant: 'destructive',
                    duration: 2000
                })
            }
        }
    }

    return (
        <>
            <div>
                <div className='mb-5'>
                    <img className='inline-block dark:hidden w-[150px]' src='/logo-full.png' alt='logo' />
                    <img className='hidden dark:inline-block w-[150px]' src='/logo-full-dark.png' alt='logo' />
                </div>

                <div className='mb-12'>
                    <p className='font-pextrabold text-2xl mb-1'>Sign in</p>
                    <p className='text-sm font-plight'>Please enter your information</p>
                </div>

                <div className="grid w-full max-w-sm items-center gap-1.5 mb-6">
                    <Label htmlFor="email">Email</Label>
                    <Input value={form.email} onChange={(e) => {setForm(f => ({ ...f, email: e.target.value })); setEmailError([]);}} className='border-neutral-400/60 dark:border-neutral-400/20' type="email" id="email" placeholder="Enter your email" />
                    {emailError.map((err, k) => (<div className='text-red-500 text-sm' key={k}>{err}</div>))}
                </div>

                <div className="grid w-full max-w-sm items-center gap-1.5 mb-10">
                    <Label htmlFor="password">Password</Label>
                    <Input value={form.password} onChange={(e) => {setForm(f => ({ ...f, password: e.target.value })); setPasswordError([]);}} className='border-neutral-400/60 dark:border-neutral-400/20' type="password" id="password" placeholder="******" />
                    {passwordError.map((err, k) => (<div className='text-red-500 text-sm' key={k}>{err}</div>))}
                </div>

                <div className="grid w-full max-w-sm items-center gap-1.5 mb-5">
                    <Button className='w-full mb-5 bg-green-600 text-white' onClick={handleLogin} disabled={isAuthenticating}>
                        {
                            isAuthenticating ?
                                <PiSpinnerBold className='w-6 h-6 animate-spin' /> :
                                <span>Sign in</span>
                        }
                    </Button>

                    {/* <div className='text-center text-[12px] font-pmedium'>
                        Don't have an account ? <Link to='/signup' className='text-green-500'>Sign Up</Link>
                    </div> */}
                </div>




            </div>
        </>
    )
}

export default Login