import { Button } from '@/_components/ui/button'
import { Label } from '@/_components/ui/label'
import {
    InputOTP,
    InputOTPGroup,
    InputOTPSeparator,
    InputOTPSlot,
} from "@/_components/ui/input-otp"
import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { PiSpinnerBold } from 'react-icons/pi'
import { useApp } from '@/contexts/appContext'
import http from '@/lib/http'
import { toast } from '@/_components/ui/use-toast'

function Otp() {  

    const { user, isAuthenticated, isAuthenticating, token, setIsAuthenticating, setIsAuthenticated, setUser } = useApp();
    const [otp, setOtp] = useState('')

    const { pathname } = useLocation();

    function authMiddleware() {
        if (token === null) {
            window.location.href = "/auth/signin"
        }

        if (!user && !isAuthenticating) {
            window.location.href = "/auth/signin"
        }

        if (user && !user.otp) {
        //     window.location.href = "/d/overview"
        }
    }   

    useEffect(() => {
        authMiddleware()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, token, isAuthenticating])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const navigate = useNavigate();

    async function authenticateEmailOTP() {
        setIsAuthenticating(true)
        try {
            await http().post('/auth/otp', {otp})
            // console.log(resp.data)
            setIsAuthenticated(true)
            setIsAuthenticating(false)
            setUser((u) => ({...u!, otp: null}));
            navigate("/d/overview");
        } catch (error) {
            setIsAuthenticating(false)
            console.log((error as any).response.data)
            toast({
                title: "Error",
                description: (error as any).response.data.message,
                variant: 'destructive',
                duration: 3000
            })
        }
    }

    return (
        <>
            <div>
                <div className='mb-5'>
                    <img className='inline-block dark:hidden w-[150px]' src='/logo-full.png' alt='logo' />
                    <img className='hidden dark:inline-block w-[150px]' src='/logo-full-dark.png' alt='logo' />
                </div>

                <div className='mb-12'>
                    <p className='font-pextrabold text-2xl mb-1'>Verify login</p>
                    <p className='text-sm font-plight'>A one tie password has been sent to your email address. Kindly input it below</p>
                </div>

                <div className="grid w-full max-w-sm items-center gap-1.5 mb-10">
                    <Label htmlFor="otp">One time password</Label>
                    <InputOTP maxLength={6} onChange={(e) => setOtp(e)}>
                        <InputOTPGroup>
                            <InputOTPSlot className='border-slate-500/60' index={0} />
                            <InputOTPSlot className='border-slate-500/60' index={1} />
                            <InputOTPSlot className='border-slate-500/60' index={2} />
                        </InputOTPGroup>
                        <InputOTPSeparator />
                        <InputOTPGroup>
                            <InputOTPSlot className='border-slate-500/60' index={3} />
                            <InputOTPSlot className='border-slate-500/60' index={4} />
                            <InputOTPSlot className='border-slate-500/60' index={5} />
                        </InputOTPGroup>
                    </InputOTP>
                </div>

                <div className="grid w-full max-w-sm items-center gap-1.5 mb-5">
                <Button className='w-full mb-5 bg-green-600 text-white' onClick={authenticateEmailOTP} disabled={isAuthenticating}>
                        {
                            isAuthenticating ?
                            <PiSpinnerBold className='w-6 h-6 animate-spin' /> :
                            <span>Verify</span>
                        }
                    </Button>

                    <div className='text-center text-[12px] font-pmedium'>
                        Didn't get the code? <button className='text-green-500'>Resend</button>
                    </div>
                </div>




            </div>
        </>
    )
}

export default Otp