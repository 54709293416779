
import TvNews from '@/components/TvNews'
import React from 'react'

function Investments() {
    return (
        <div>
            <div className='mb-8'>
                <div className='text-2xl font-pbold'>Manage Investments</div>
                <div className='font-plight text-sm'>Invest in S&P 500 stocks.</div>
            </div>
            <div>
                <div className='grid grid-cols-1 md:grid-cols-2 gap-5'>
                    <div className=''>
                        <div className='flex justify-between items-center gap-4 mb-4'>
                            <div className='font-pbold pl-1'>
                                My Investments
                            </div>
                            {/* <div>
                                <Link to={''} className='font-pregular text-sm'>See all</Link>
                            </div> */}
                        </div>

                        {/* <div>
                            <div className='w-full rounded-xl bg-neutral-50 dark:bg-slate-900/70 px-2 py-2 mt-3'>
                                <div className='flex gap-3 justify-between items-center'>
                                    <div className='flex gap-3'>
                                        <div>
                                            <div className='w-[20px] h-[20px] rounded-full overflow-hidden'>
                                                <img className='w-full' src="/assets/tesla2.png" alt="" />
                                            </div>
                                        </div>
                                        <div>Tesla</div>
                                    </div>

                                    <div className='text-[13px] text-green-600 font-pmedium'>+0.33%</div>
                                </div>
                            </div>

                            <div className='w-full rounded-xl bg-neutral-50 dark:bg-slate-900/70 px-2 py-2 mt-3'>
                                <div className='flex gap-3 justify-between items-center'>
                                    <div className='flex gap-3'>
                                        <div>
                                            <div className='w-[20px] h-[20px] rounded-full overflow-hidden'>
                                                <img className='w-full' src="/assets/apple.png" alt="" />
                                            </div>
                                        </div>
                                        <div>Apple Inc.</div>
                                    </div>

                                    <div className='text-[13px] text-red-600 font-pmedium'>-0.98%</div>
                                </div>
                            </div>

                            <div className='w-full rounded-xl bg-neutral-50 dark:bg-slate-900/70 px-2 py-2 mt-3'>
                                <div className='flex gap-3 justify-between items-center'>
                                    <div className='flex gap-3'>
                                        <div>
                                            <div className='w-[20px] h-[20px] rounded-full overflow-hidden'>
                                                <img className='w-full' src="/assets/nike.png" alt="" />
                                            </div>
                                        </div>
                                        <div>Nike</div>
                                    </div>

                                    <div className='text-[13px] text-red-600 font-pmedium'>-3.33%</div>
                                </div>
                            </div>

                            <div className='w-full rounded-xl bg-neutral-50 dark:bg-slate-900/70 px-2 py-2 mt-3'>
                                <div className='flex gap-3 justify-between items-center'>
                                    <div className='flex gap-3'>
                                        <div>
                                            <div className='w-[20px] h-[20px] rounded-full overflow-hidden'>
                                                <img className='w-full' src="/assets/meta.png" alt="" />
                                            </div>
                                        </div>
                                        <div>Meta</div>
                                    </div>

                                    <div className='text-[13px] text-green-600 font-pmedium'>+0.33%</div>
                                </div>
                            </div>

                            <div className='w-full rounded-xl bg-neutral-50 dark:bg-slate-900/70 px-2 py-2 mt-3'>
                                <div className='flex gap-3 justify-between items-center'>
                                    <div className='flex gap-3'>
                                        <div>
                                            <div className='w-[20px] h-[20px] rounded-full overflow-hidden'>
                                                <img className='w-full' src="/assets/alphabet.png" alt="" />
                                            </div>
                                        </div>
                                        <div>Alphabet</div>
                                    </div>

                                    <div className='text-[13px] text-red-600 font-pmedium'>-4.33%</div>
                                </div>
                            </div>
                        </div> */}

                    </div>

                    <div>
                        <TvNews />
                    </div>
                </div>

                <div className='flex gap-10 '>
                    <div></div>
                </div>
            </div>
        </div>
    )
}

export default Investments